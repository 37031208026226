<template>
  <BModal
    noCloseOnBackdrop
    @close="closeModalEmit"
    id="driver_modal"
    title="افزودن مورد جدید"
    hideFooter
    size="lg"
  >
    <BCard no-body>
      <BTabs v-model="tabIndex" :align="'center'" small card>
        <BTab title="استعلام">
          <BForm @submit.prevent="inquirySubmitHandler">
            <FormSchema v-model="inquiryFormData" :schema="schema_inquiry" />
            <BButton variant="primary" type="submit" :disabled="disabledProps"
              >استعلام</BButton
            >
            <BButton
              @click="closeModalEmit"
              variant="warning"
              type="button"
              class="mr-3"
              :disabled="disabledProps"
              >بستن</BButton
            >
          </BForm>
        </BTab>
        <BTab title="راننده" :disabled="disabledTab">
          <BForm class="w-100" @submit.prevent="addSubmitHandler">
            <div class="d-flex align-items-center mb-5">
              <div class="col-12 col-md-5">
                <span>نام نام خانوادگی : </span>
                <span class="font-bold">{{
                  driver_information?.name ?? "تعریف نشده"
                }}</span>
              </div>
              <div class="col-12 col-md-4">
                <span>کد ملی : </span>
                <span class="font-bold">{{
                  driver_information?.national_code ?? "تعریف نشده"
                }}</span>
              </div>
            </div>
            <BButton variant="primary" type="submit" :disabled="disabledProps"
              >افزودن</BButton
            >
            <BButton
              @click="closeModalEmit"
              variant="warning"
              type="button"
              class="mr-3"
              :disabled="disabledProps"
              >بستن</BButton
            >
          </BForm>
        </BTab>
        <BTab class="px-3 py-4" title="جدید">
          <BForm @submit.prevent="submitHandlerEmit">
            <div class="d-flex">
              <FormSchema v-model="formDataProps" :schema="schema_new" />
            </div>
            <div class="col-12 mt-4">
              <BButton variant="primary" type="submit" :disabled="disabledProps"
                >ثبت</BButton
              >
              <BButton
                @click="closeModalEmit"
                variant="warning"
                type="button"
                class="mr-3"
                :disabled="disabledProps"
                >بستن</BButton
              >
            </div>
          </BForm>
        </BTab>
      </BTabs>
    </BCard>
  </BModal>
</template>
<script>
import {
  BModal,
  VBModal,
  BTabs,
  BTab,
  BButton,
  BForm,
  BCard,
} from "bootstrap-vue";
import FormSchema from "@@/core/components/shared/form/FormSchema";
import { errorNotif, successNotif } from "@@/core/helpers/functions";
import { computed } from "vue";
export default {
  components: { BModal, BTabs, BTab, BButton, BCard, BForm, FormSchema },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    createProps: {
      type: Object,
      default: () => {},
    },
    disabledProps: {
      type: Boolean,
      default: false,
    },
    formDataProps: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tabIndex: 0,
      disabledTab: true,
      driver_information: {},
      schema_inquiry: [
        {
          tag: "input",
          type: "number",
          name: "national_code",
          class: "col-12 col-md-5",
          readonly: false,
          required: true,
          label: "کدملی",
          placeholder: "کدملی",
        },
      ],
      schema_new: [
        {
          tag: "input",
          type: "text",
          name: "name",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "نام و نام خانوادگی",
          placeholder: "نام و نام خانوادگی",
        },
        {
          tag: "input",
          type: "tel",
          name: "mobile",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "شماره موبایل",
          placeholder: "شماره موبایل",
        },
        {
          tag: "input",
          type: "number",
          name: "national_code",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "کدملی",
          placeholder: "کدملی",
        },
        {
          tag: "input",
          type: "text",
          name: "address",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "آدرس",
          placeholder: "آدرس",
        },
        {
          tag: "input",
          type: "text",
          name: "car_model",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "مدل خودرو",
          placeholder: "مدل خودرو",
        },
        {
          tag: "input",
          type: "text",
          name: "car_color",
          class: "col-12 col-md-4",
          readonly: false,
          required: false,
          label: "رنگ خودرو",
          placeholder: "رنگ خودرو",
        },
        {
          tag: "input",
          type: "text",
          name: "car_name",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "نام خودرو",
          placeholder: "نام خودرو",
        },
        {
          tag: "input",
          type: "number",
          name: "plaque_1",
          class: "col-12 col-md-1 p-1",
          readonly: false,
          required: true,
          label: "پلاک",
          placeholder: "59",
        },
        {
          tag: "input",
          type: "number",
          name: "plaque_2",
          class: "col-12 col-md-1 p-1",
          readonly: false,
          required: false,
          label: ".",
          placeholder: "111",
        },
        {
          tag: "input",
          type: "text",
          name: "plaque_3",
          class: "col-12 col-md-1 p-1",
          readonly: false,
          required: false,
          label: ".",
          placeholder: "ج",
        },
        {
          tag: "input",
          type: "number",
          name: "plaque_4",
          class: "col-12 col-md-1 p-1",
          readonly: false,
          required: false,
          label: ".",
          placeholder: "11",
        },
        {
          tag: "date",
          type: "date",
          name: "license_expiration_date",
          class: "col-12 col-md-4",
          format: "YYYY/MM/DD",
          displayFormat: "jYYYY/jMM/jDD",
          readonly: false,
          required: true,
          label: "تاریخ صدور مجوز",
          placeholder: "تاریخ صدور مجوز",
        },
        {
          tag: "input",
          type: "text",
          name: "bank_name",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "نام بانک",
          placeholder: "نام بانک",
        },
        {
          tag: "input",
          type: "text",
          name: "sheba_number",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "شماره شبا",
          placeholder: "شماره شبا",
        },
        {
          tag: "input",
          type: "number",
          name: "account_number",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "شماره حساب",
          placeholder: "شماره حساب",
        },
        {
          tag: "input",
          type: "number",
          name: "card_number",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "شماره کارت",
          placeholder: "شماره کارت",
        },
        {
          tag: "file",
          name: "image",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "تصویر راننده",
        },
        {
          tag: "file",
          name: "license",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "تصویر مجوز",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.createProps.genders),
          name: "gender",
          title: "label",
          track_by: "value",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "جنسیت",
          placeholder: "جنسیت",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.createProps.carTypes),
          name: "car_type",
          title: "label",
          track_by: "value",
          class: "col-12 col-md-4",
          readonly: false,
          required: true,
          label: "نوع خودرو",
          placeholder: "نوع خودرو",
        },
      ],
      inquiryFormData: {
        national_code: null,
      },
    };
  },
  methods: {
    errorNotif,
    successNotif,
    submitHandlerEmit() {
      this.$emit("on-submit-handler");
    },
    closeModalEmit() {
      this.$emit("on-close-modal");
    },
    async inquirySubmitHandler() {
      try {
        let fd = new FormData();
        fd.append("national_code", this.inquiryFormData.national_code);
        const response = await this.$axios.post("agency/drivers/inquiry", fd);
        this.driver_information = response.data.data.driver;
        if (this.driver_information && this.driver_information.id) {
          this.tabIndex = 1;
          this.disabledTab = false;
        } else {
          this.tabIndex = 2;
        }
        this.successNotif(response);
      } catch (error) {
        this.errorNotif(error);
      }
    },
    async addSubmitHandler() {
      try {
        let fd = new FormData();
        fd.append("driver_id", this.driver_information.id);
        const response = await this.$axios.post("agency/drivers/add", fd);
        this.closeModalEmit();
        this.tabIndex = 0;
        this.disabledTab = true;
        this.successNotif(response);
      } catch (error) {
        this.errorNotif(error);
      }
    },
  },
};
</script>
<style>
#driver_modal .card {
  border: 1px solid #e9ebfa !important;
  overflow: hidden;
}
#driver_modal .card-header {
  padding: 0.7rem 1.5rem 0 1.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #e9ebfa !important;
}
#driver_modal .card-body {
  border: 0 !important;
}
#driver_modal .nav-tabs {
  border: 0;
}
#driver_modal .nav-tabs .nav-item .nav-link {
  display: block;
  padding: 0rem 1rem;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
#driver_modal .nav-tabs .small,
small {
  font-size: 85%;
  font-weight: 400;
}
#driver_modal .nav-tabs .nav-item .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
</style>
