import {
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BTable,
  BBadge,
  BAlert,
  VBModal,
  BFormTextarea,
  BFormFile,
  BCollapse,
  VBToggle,
  BCard,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
import AssignDataToForm from "@@/core/components/classes/assignDataToForm";
import FormRequest from "@@/core/components/classes/formRequest";
import InputGroup from "@@/core/components/shared/inputGroup/InputGroup";
import Multiselects from "@@/core/components/shared/multiselect/Multiselects";
import MainLayout from "@@/core/components/layouts/MainLayout";
import FilterRequest from "@@/core/components/classes/filterRequest";
import StatusBadge from "@@/core/components/shared/status/StatusBadge";
import {
  errorNotif,
  successNotif,
  changePage,
  refreshDataObject,
} from "@@/core/helpers/functions";
export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BTable,
    BBadge,
    BAlert,
    BFormTextarea,
    BFormFile,
    BCollapse,
    BCard,
    BOverlay,
    BFormSelect,
    Multiselects,
    InputGroup,
    MainLayout,
    StatusBadge,
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      id: null,
      mode: "create",
      url: null,
      edit_key: null,
      init_key: null,
      edit: null,
      initData: null,
      create: null,
      disabled: false,
      loading: false,
      keys: {},
      cities: [],
      hasCreate: false,
      location: {},
      create: {},
      modalId: null,
      backRoute: null,
      reqUrl: null,
      hasTranslate: false,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    "$route.query": function (newValue) {
      if (newValue && newValue.page) {
        this.loadInitData(null, { page: newValue.page });
      }
    },
  },
  methods: {
    errorNotif,
    changePage,
    refreshDataObject,
    successNotif,
    init() {
      if (this.$route.params.id) {
        this.id = this.$route.params.id;
        this.mode = "edit";
      }
      if (this.hasCreate) {
        this.loadCreateData();
      }
      if (this.hasTranslate) {
        this.tranlator();
      }
      this.loadInitData();
    },
    async loadInitData(edit = null, filterQueries = {}) {
      this.disabled = true;
      this.loading = true;
      try {
        if (this.mode == "edit" && this.id) {
          const responseEdit = await this.$axios.get(`${this.url}/${this.id}`);
          this.edit = responseEdit.data.data[this.edit_key];
          this.$nextTick(() => {
            new AssignDataToForm(this.edit, this.$data);
          });
        } else if (edit) {
          this.edit = edit;
          this.id = edit.id;
          this.mode = "edit";
          this.showModal();
          this.$nextTick(() => {
            new AssignDataToForm(this.edit, this.$data);
          });
        } else {
          const responseInit = await this.$axios.get(this.url, {
            params: { ...filterQueries },
          });
          this.initData = responseInit.data.data[this.init_key];
        }
      } catch (error) {
        this.errorNotif(error);
      } finally {
        this.disabled = false;
        this.loading = false;
      }
    },
    async loadCreateData() {
      this.disabled = true;
      try {
        const responseCreate = await this.$axios.get(`${this.url}/create`);
        this.create = responseCreate.data.data;
      } catch (error) {
        this.errorNotif(error);
      } finally {
        this.disabled = false;
      }
    },
    async submitHandler() {
      this.disabled = true;
      const request = new FormRequest(this.$data);
      let data = await request.generateRequestData();
      try {
        if (this.id && this.mode == "edit") {
          const responseSubmitEdit = await this.$axios.post(
            this.reqUrl ? this.reqUrl : `${this.url}/${this.id}`,
            data
          );
          this.successNotif(responseSubmitEdit);
          this.closeModal();

          this.back();
          this.refreshDataObject(
            this.initData,
            responseSubmitEdit,
            this.edit_key,
            this.id
          );
        } else {
          const responseSubmitCreate = await this.$axios.post(this.url, data);
          this.initData?.data?.unshift(
            responseSubmitCreate.data.data[this.edit_key]
          );
          this.successNotif(responseSubmitCreate);
          this.closeModal();

          this.back();
        }
      } catch (error) {
        this.errorNotif(error);
      } finally {
        this.disabled = false;
      }
    },
    async getCities($event) {
      if ($event && $event.id) {
        this.disabled = true;
        try {
          const responseCities = await this.$axios.get(
            `/all/cities/${$event.id}`
          );
          this.cities = responseCities.data.data.cities;
        } catch (error) {
          this.errorNotif(error);
        } finally {
          this.disabled = false;
        }
      } else {
        return;
      }
    },
    deleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            const url = this.url.includes("?")
              ? this.url.substr(0, this.url.indexOf("?"))
              : this.url;
            this.$axios
              .delete(url + `/${id}`)
              .then((response) => {
                if (Array.isArray(this.initData)) {
                  this.initData = this.initData.filter((item) => item.id != id);
                } else {
                  this.initData.data = this.initData.data.filter(
                    (item) => item.id != id
                  );
                }
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
    closeModal() {
      this.$root.$emit("bv::hide::modal", this.modalId);
      this.id = null;
      this.mode = "create";
    },
    async showModal() {
      await this.$root.$emit("bv::show::modal", this.modalId);
    },
    async tranlator() {
      this.disabled = true;
      try {
        const responseKeys = await this.$axios.get("/all/translates");
        this.keys = responseKeys.data.data.keys;
      } catch (error) {
        this.errorNotif(error);
      } finally {
        this.disabled = false;
      }
    },
    back() {
      if (this.backRoute) {
        this.$router.push(this.backRoute);
      }
    },
    applyFilter() {
      const request = new FilterRequest(this.$data);
      const query = request.generateFilterQueries();
      this.loadInitData(null, query);
    },
    removeFilter() {
      this.init();
    },
  },
};
