<template>
  <MainLayout hasModal :modalName="modalId">
    <template #main-content>
      <BTable
        v-if="initData && initData.data && initData.data.length"
        responsive
        hover
        bordered
        :items="initData.data"
        :fields="fields"
      >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(title)="data">
          {{ data.item.title }}
        </template>
        <template v-slot:cell(status)="data">
          <StatusBadge :status="data.item.status" />
        </template>
        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | dateTime }}
        </template>
        <template v-slot:cell(edit)="data">
          <BButton @click="loadInitData(data.item)" variant="outline-primary">
            <i class="fas fa-edit"></i
          ></BButton>
        </template>
        <template v-slot:cell(delete)="data">
          <BButton @click="deleteItem(data.item.id)" variant="outline-danger">
            <i class="fas fa-trash-alt"></i>
          </BButton>
        </template>
      </BTable>
      <div v-else class="alert alert-danger">موردی یافت نشد!</div>
      <pagination
        v-if="initData && initData.data && initData.data.length"
        :disabled="disabled"
        :limit="2"
        :data="initData"
        @pagination-change-page="changePage"
      >
      </pagination>
    </template>
    <template #modals>
      <BModal
        noCloseOnBackdrop
        @close="closeModal"
        id="shifts_modal"
        title="افزودن مورد جدید"
        hideFooter
      >
        <BForm @submit.prevent="submitHandler">
          <FormSchema :schema="schema" v-model="formData" />
          <div class="d-flex mt-4">
            <BButton variant="primary" type="submit" :disabled="disabled"
              >ثبت</BButton
            >
            <BButton
              class="mr-3"
              @click="closeModal"
              variant="warning"
              type="button"
              :disabled="disabled"
              >بستن</BButton
            >
          </div>
        </BForm>
      </BModal>
    </template>
  </MainLayout>
</template>
<script>
import crudMixin from "@@/core/mixins/crudMixin";
import MainLayout from "@@/core/components/layouts/MainLayout";
import FormSchema from "@@/core/components/shared/form/FormSchema";

export default {
  mixins: [crudMixin],
  components: { MainLayout, FormSchema },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      schema: [
        {
          tag: "input",
          type: "text",
          name: "title",
          class: "col-12",
          readonly: false,
          required: true,
          label: "شیفت",
          placeholder: "شیفت",
        },
        {
          tag: "checkbox",
          name: "status",
          class: "col-12",
          readonly: false,
          required: false,
          label: "وضعیت",
          placeholder: "وضعیت",
        },
      ],
      formData: {
        title: null,
        status: true,
      },
    };
  },
  created() {
    this.url = "/admin/shifts";
    this.edit_key = "shift";
    this.init_key = "shifts";
    this.modalId = "shifts_modal";
    this.hasCreate = false;
  },
};
</script>
<style scoped></style>
