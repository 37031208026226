<template>
  <div class="app-header header">
    <div class="container-fluid">
      <div class="d-flex">
        <div class="d-flex order-lg-2 my-auto mr-auto">
          <a
            class="nav-link my-auto icon p-0 nav-link-lg d-md-none navsearch"
            href="#"
            data-toggle="search"
          >
            <i class="feather feather-search search-icon header-icon"></i>
          </a>
          <div class="dropdown profile-dropdown">
            <a href="#" class="nav-link icon" data-toggle="dropdown">
              <span>
                <i class="feather feather-log-out header-icon"></i>
              </span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-left dropdown-menu-arrow animated"
            >
              <h6 v-if="adminProfile" class="text-center font-bold pt-2">
                {{ adminProfile.name }}
              </h6>
              <a
                class="dropdown-item d-flex cursor-pointer"
                data-toggle="modal"
                data-target="#changepasswordnmodal"
              >
                <i class="feather feather-edit-2 ml-3 fs-16 my-auto"></i>
                <div class="mt-1">تغییر رمزعبور</div>
              </a>
              <a
                class="dropdown-item d-flex cursor-pointer"
                @click="logOut()"
                :disabled="disabled"
              >
                <i class="feather feather-power ml-3 fs-16 my-auto"></i>
                <div class="mt-1">خروج</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderImpl from "@@/core/components/superAdmin/shared/Header/HeaderImpl";
export default {
  extends: HeaderImpl,
};
</script>

<style
  scoped
  src="@@/core/components/superAdmin/shared/Header/HeaderStylePublic.css"
></style>
