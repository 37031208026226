export default class FilterRequest {
  constructor($data) {
    this.filter = $data.filter;
  }
  generateFilterQueries() {
    let data = {
      // city_id: this.formData.city ? this.formData.city.id : null,
      city_id: 862, //Static for now
      title: this.filter.title ? this.filter.title : null,
      manager_name: this.filter.manager_name ? this.filter.manager_name : null,
      name: this.filter.name ? this.filter.name : null,
      mobile: this.filter.mobile ? this.filter.mobile : null,
      grade_id: this.filter.grade ? this.filter.grade.id : null,
      shift_id: this.filter.shift ? this.filter.shift.id : null,
      car_type: this.filter.car_type ? this.filter.car_type.value : null,
      school_type_id: this.filter.school_type ? this.filter.school_type.id : null,
      gender: this.filter.gender ? this.filter.gender.value : null,
      is_traffic: this.statusResolver(this.filter.is_traffic),
      status: this.statusResolver(this.filter.status),
    };

    let _data = this.removeEmpty(data);

    return _data;
  }
  statusResolver(status) {
    if (typeof status === "boolean") {
      if (status) {
        return 1;
      } else {
        return 0;
      }
    } else if (typeof status === "object") {
      return status?.value;
    } else {
      return null;
    }
  }
  removeEmpty(obj) {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  }
}
