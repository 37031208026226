<template>
  <MainLayout>
    <template #main-content>
      <BForm class="px-5" @submit.prevent="submitHandler">
        <div class="d-flex align-items-center mb-4">
          <h5 class="mb-0 font-bold">نام و نام خانوادگی :</h5>
          <span class="mr-3">{{ edit?.name }}</span>
        </div>
        <div class="d-flex align-items-center mb-4">
          <h5 class="mb-0 font-bold">شماره موبایل :</h5>
          <span class="mr-3">{{ edit?.phone_number ?? "ندارد" }}</span>
        </div>
        <div class="d-flex align-items-center mb-5">
          <h5 class="mb-0 font-bold">پیام :</h5>
          <span class="mr-3">{{ edit?.message }}</span>
        </div>
        <FormSchema v-model="formData" :schema="schema"></FormSchema>
        <div class="col-12">
          <BButton variant="primary" type="submit" :disabled="disabled"
            >ثبت</BButton
          >
        </div>
      </BForm>
    </template>
  </MainLayout>
</template>
<script>
import crudMixin from "@@/core/mixins/crudMixin";
import MainLayout from "@@/core/components/layouts/MainLayout";
import FormSchema from "@@/core/components/shared/form/FormSchema";

export default {
  components: {
    crudMixin,
    MainLayout,
    FormSchema,
  },
  mixins: [crudMixin],
  data() {
    return {
      schema: [
        {
          tag: "textarea",
          name: "description",
          class: "col-12",
          required: true,
          label: "توضیحات ادمین",
          placeholder: "توضیحات",
        },
      ],
      formData: {
        description: null,
      },
    };
  },
  created() {
    this.url = "/admin/contacts";
    this.edit_key = "contact";
    this.init_key = "contacts";
    this.backRoute = '/super-admin/contacts'
  },
};
</script>
