<template>
  <main>
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <p class="font-bold">{{ titleComputed }}</p>
          <slot name="title"></slot>
        </div>
        <div class="action-btn">
          <BButton v-if="hasFilter" v-b-toggle.search_filter variant="primary"
            ><i class="fas fa-search"></i
          ></BButton>
          <router-link
            v-if="hasDetail"
            class="btn btn-success"
            :to="navigateTo"
          >
            افزودن مورد جدید
          </router-link>
          <BButton v-if="hasModal" v-b-modal="modalName" variant="success"
            >افزودن / ویرایش</BButton
          >
          <slot name="action-btns"></slot>
        </div>
      </div>
      <div class="card-body">
        <BCollapse id="search_filter">
          <BCard>
            <slot name="filter-fields"></slot>
          </BCard>
        </BCollapse>
        <div>
          <BOverlay :show="isLoading" z-index="1050" rounded="lg" opacity="0.6"
            ><slot name="main-content"></slot
          ></BOverlay>
        </div>
      </div>
    </div>
    <div>
      <slot name="modals"></slot>
    </div>
  </main>
</template>
<script>
import {
  BCollapse,
  BCard,
  BForm,
  BButton,
  VBModal,
  VBToggle,
  BOverlay,
} from "bootstrap-vue";
export default {
  name: "mainlayout",
  components: { BCollapse, BCard, BForm, BButton, BOverlay },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
  },
  props: {
    hasFilter: {
      type: Boolean,
      default: false,
    },
    hasModal: {
      type: Boolean,
      default: false,
    },
    hasDetail: {
      type: Boolean,
      default: false,
    },
    modalName: {
      type: String,
      default: "",
    },
    navigateTo: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    titleComputed() {
      return document.title;
    },
  },
};
</script>
<style scoped>
.card .card-header {
  border: 0;
  align-items: center;
  justify-content: space-between;
}
.card .action-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
