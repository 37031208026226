<template>
  <aside class="app-sidebar">
    <div class="app-sidebar__logo">
      <router-link to="/super-admin" class="header-brand">
        <h2 class="text-white">نیکران</h2>
      </router-link>
    </div>
    <div class="text-white app-sidebar3">
      <Menu></Menu>
    </div>
  </aside>
</template>

<script>
import SidebarImpl from "@@/core/components/companies/shared/Sidebar/SidebarImpl";
export default {
  extends: SidebarImpl,
};
</script>

<style src="@@/core/components/companies/shared/Sidebar/SidebarStylePublic.css"></style>
