<template>
  <MainLayout hasModal :modalName="modalId">
    <template #action-btns>
      <BButton v-if="edit && edit.is_printable" variant="primary" @click="print"
        ><i class="fas fa-print"></i
      ></BButton>
    </template>
    <template #main-content>
      <div class="row">
        <div class="container border rounded py-5 px-5 textContract">
          <div class="content_textContract">
            <div>
              <div
                class="d-flex align-items-center justify-content-between mb-5"
              >
                <h2 class="font-bold text-lg mb-2">
                  فرم قرارداد داخلی شرکت حمل ونقل سرویس مدارس
                </h2>
                <div class="dont-print">
                  <StatusBadge v-if="edit" :status="edit.status" />
                </div>
              </div>
              <div class="d-flex align-items-center mb-4">
                <div class="d-flex align-items-center ml-4">
                  <span class="font-bold ml-2">تاریخ شروع قرارداد :</span>
                  <span v-if="edit && edit.start_date">{{
                    edit.start_date | date
                  }}</span>
                  <span v-else>ثبت نشده</span>
                </div>
                <div class="d-flex align-items-center">
                  <span class="font-bold ml-2">تاریخ پایان قرارداد :</span>
                  <span v-if="edit && edit.end_date">{{
                    edit.end_date | date
                  }}</span>
                  <span v-else>ثبت نشده</span>
                </div>
              </div>
              <div>
                <p>
                  شرکتهای سرویس مدارس درقبال خدمات بدون قرارداد هیچ تعهدی به
                  خانواده وراننده ندارند (اولیاءمحترم قرارداد شرکت سرویس مدارس
                  بدون مهروامضاء مسئول شرکت فاقداعتبار وارزش جهت پیگیری قانونی
                  میباشد) . شرکتهای سرویس مدارس فقط درصورت تکمیل ظرفیت خودرو
                  ویاتعهد اولیاء نسبت به پرداخت هزینه کل ظرفیت خودرو قادربه
                  ارائه خدمات میباشند .
                </p>
                <div>
                  <div>
                    <p class="font-bold">مشخصات وشرح وظایف راننده :</p>
                  </div>
                  <div class="row">
                    <p class="col-md-4 col-12">
                      <span> نام و نام خانوادگی راننده : </span>
                      <span class="font-bold">{{
                        edit?.driver?.name ?? "ثبت نشده"
                      }}</span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> کدملی : </span>
                      <span class="font-bold">{{
                        edit?.driver?.national_code ?? "ثبت نشده"
                      }}</span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> تلفن همراه : </span>
                      <span class="font-bold">{{
                        edit?.driver?.mobile ?? "ثبت نشده"
                      }}</span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> نوع خودرو رنگ : </span>
                      <span class="font-bold">
                        {{
                          edit?.driver
                            ? `${edit?.driver?.car_name} - ${edit?.driver?.car_color} - ${edit?.driver?.car_model}`
                            : "ثبت نشده"
                        }}
                      </span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> پلاک خودرو : </span>
                      <span class="font-bold">{{
                        edit?.driver?.plaque ?? "ثبت نشده"
                      }}</span>
                    </p>
                  </div>
                  کلیه رانندگان محترم ملزم به انجام تعهدات شرکت وابلاغیه های
                  سازمان حمل ونقل میباشد .
                </div>
                <p></p>
                <div>
                  <div>
                    <p class="font-bold">مشخصات اولیاء دانش آموز:</p>
                  </div>
                  <div class="row">
                    <p class="col-md-4 col-12">
                      <span> نام ونام خانوادگی : </span>
                      <span class="font-bold">{{
                        edit?.family?.name ?? "ثبت نشده"
                      }}</span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> نام دانش آموز: </span>
                      <span class="font-bold">
                        {{ edit?.child?.name ?? "ثبت نشده" }}
                      </span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> شماره همراه پدر: </span>
                      <span class="font-bold">
                        {{ edit?.father_mobile ?? "ثبت نشده" }}
                      </span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> شماره همراه مادر: </span>
                      <span class="font-bold">
                        {{ edit?.mother_mobile ?? "ثبت نشده" }}
                      </span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> تلفن ثابت: </span>
                      <span class="font-bold">
                        {{ edit?.telephone ?? "ثبت نشده" }}
                      </span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> آدرس منزل : </span>
                      <span class="font-bold">
                        {{ edit?.address ?? "ثبت نشده" }}
                      </span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> نام مدرسه : </span>
                      <span class="font-bold">
                        {{ edit?.school?.title ?? "ثبت نشده" }}
                      </span>
                    </p>
                    <p class="col-md-4 col-12">
                      <span> مقطع تحصیلی: </span>
                      <span class="font-bold">
                        {{ edit?.grade?.title ?? "ثبت نشده" }}
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <p class="font-bold">وظایف اولیاءدانش آموز:</p>
                  <ol>
                    <li>
                      <p>
                        فرزندم شئونات اخلاقی وکلامی رارعایت و با پوشش مناسب
                        الالخصوص رعایت حجاب برای دخترخانم ها که باید هنگام
                        استفاده از سرویس مقنعه استفاده نمایند درصورت بی توجهی
                        وعدم توجه واخطارراننده توسط شرکت به اولیاء شرکت مجازبه
                        لغوقراردادبوده واولیاء مکلف به پرداخت خسارت راننده
                        میباشد .
                      </p>
                    </li>
                    <li>
                      <p>
                        دانش آموزان بایدراس ساعت آماده باشندوراننده اززمان توافق
                        با خانواده فقط2 دقیقه موظف به توقف میباشد ودرزمان ارائه
                        خدمات با رانندگان تماس نگیرند ضمنأ درصورت عدم حضوردانش
                        آموز راننده مکلف است به خدمات ادامه دهد (شروع اولین
                        خدمات راننده وسوارشدن دانش آموزی ساعت 6/30 صبح میباشد)
                        مگرراننده با خانواده توافق نماید لذا باید درقرارداد ذکر
                        شود .
                      </p>
                    </li>
                    <li>
                      <p>
                        رانندگان واولیاء درصورت بروزمشکل جهت رفع آن با مدیریت
                        شرکت همراه
                        <span class="font-bold">{{ edit?.agency?.telephone ?? "ثبت نشده" }}</span> دفتر
                        <span class="font-bold">{{ edit?.agency?.title ?? "ثبت نشده" }}</span> تماس
                        بگیرند . اولیاء محترم شکایت خودازرانندگان را مکتوب گزارش
                        دهیدتا شرکتهای سرویس مدارس قادربه رسیدگی باشند .
                        اگرشکایت بصورت تلفنی باشد بعنوان گوش زد به راننده تلقی
                        میگردد و قابلیت پیگیری ندارد (اعمال قانون پس ازسه شکایت
                        کتبی ازطرف خانواده واخطارشرکت به راننده انجانم میگردد)
                      </p>
                    </li>
                    <li>
                      <p>
                        مسئولیت قبل ازسوارشدن و بعد ازپیاده شدن درزمان تعیین شده
                        بعهده اولیاء دانش آموز می باشد .
                      </p>
                    </li>
                    <li>
                      <p>
                        چنانچه اولیاء درطی سال تحصیلی مستاجرو تغییرمکان داشته
                        قبل ازعقد قرارداد به راننده وشرکت اعلام تا در قرارداد
                        ثبت گردد . لازم بذکراست بعد ازشروع خدمات درصورت کنسل
                        کردن قرارداد و یا جابجایی و عدم توانایی راننده جهت ارائه
                        خدمات خانواده مشمول پرداخت خسارت برابر قوانین ابلاغی
                        میباشد .
                      </p>
                    </li>
                    <li>
                      <p>
                        اگرراننده به هردلیلی توسط راننده خدمات ارائه نگردد (
                        بیماری ویا خرابی خودرو) اولیاء دانش آموزان می
                        توانندکرایه رفت وبرگشت دانش آموزرا بصورت آژانس را
                        ازشهریه راننده کسر نماید( اگرراننده دنبال هیچ دانش آموزی
                        نرفته باشد) اولیاءدانش آموزان درهمان ماه مجاز به کسر
                        هزینه میباشد
                      </p>
                    </li>
                    <li>
                      <p>
                        تعطیلات نوروزی و اضطراری مانندکرونا ازشهریه کسرخواهد شد
                        درصورت رضایت خانواده تعطیلات نوروزی بعنوان عیدی و پاداش
                        راننده محسوب وشهریه فروردین کامل دریافت میگرددپس از قبول
                        شرایط وعقذ قرارداداولیاء متعهد پرداخت مبالغ ثبت شده
                        میباشد
                      </p>
                    </li>
                    <li>
                      <p>
                        پرداخت هرگونه وجه درموقع امتحانات نوبت اول ودوم منوط به
                        ارائه خدمات حمل ونقل به دانش آموزان میباشد(چنانچه
                        اولیاءخود به هردلیلی قصد بردن وآوردن دانش آموزراداشته
                        باشد مشمول پرداخت هزینه میباشد مگردرهنگام
                        عقدقراردادباراننده توافق نماید)
                      </p>
                    </li>
                    <li>
                      <p>
                        تعهدرانندگان برای ارائه خدمات فقط ازمبدأ به مقصد تعیین
                        شده درقراردادمیباشد ورانندگان درقبال ارائه خدمات درمبدأ
                        و مقصد دیگر تعهدی ندارند ودرصورت ارائه خدمات مانند (
                        اردو ؛ کلاس های تقویتی ؛کیف درمدرسه منزل بستگان وغیره
                        ...........) اولیاءدانش آموزان جدای از شهریه مکلف به
                        پرداخت کرایه آژانس به رانندگان میباشند .
                      </p>
                    </li>
                    <li>
                      <p>
                        تعهدرانندگان برای زنگ مدارس بطورثابت درطول هفته میباشند
                        وچنانچه زنگ مدرسه ای مانند دوره اول ودوم متوسطه هنگام
                        برگشت درهفته یک یا دوروز تغییرداشته باشددانش آموزان مکلف
                        است درمدرسه منتظر بماند تا راننده تعهد ثابت خودرا انجام
                        سپس می تواند خدمات مدرسه تغییر یافته را انجام دهد.
                      </p>
                    </li>
                    <li>
                      <p>
                        ازآنجائیکه درسنوات قبل شهریه رانندگان در پایان هرماه
                        دریافت می شد وازاین بابت متاسفانه بعلت بی توجهی بعضی
                        ازخانوادها منجر به ضرروزیان رانندگان مخصوصا درسه ماهه
                        پایان سال می شد لذا برای جلوگیری از ضرر وزیان احتمالی
                        رانندگان حق الزحمه رانندگان را قبل ازبازگشایی و شروع
                        خدمات پرداخت و هرماهه ازیکم تا پنجم پرداخت نمایند و حق
                        الزحمه اسفند از 20 تا25 پرداخت گردد ودرصورتیکه اولیاء تا
                        زمان اعلام شده حق الزحمه رانندگان را نپردازند؛راننده پس
                        از اطلاع رسانی به شرکت مجاز به قطع خدمات میباشد وچنانچه
                        هزینه خدمات تا نیمه ماه پرداخت نشود شرکت مجازبه فسخ
                        قرارداد و دریافت خسارت راننده طبق موازین میباشد ودرصورت
                        پرداخت هزینه وادامه خدمات مدت زمان رفع مشکل ازشهریه
                        راننده کسرنمیشود .
                      </p>
                    </li>
                    <li>
                      <p>
                        همچنین اولیاء محترم یک فقره چک یا سفته به مبلغ
                        <span class="font-bold"> {{ "ثبت نشده" }} </span>ریال جهت
                        تضمین اجرا قرارداد نزدشرکت گذاشته ودرصورت عدم ارائه چک
                        یا سفته می تواند شهریه آخرسال را هنگام تنظیم
                        قراردادنزدشرکت به ودیعه بگذارد حق الزحمه ماه آخرراننده
                        توسط شرکت تسویه میگردد ( درصورت تضمین باچک ویاسفته
                        درپایان قراردادبه اولیاء مسترد میگردد )
                      </p>
                    </li>
                  </ol>
                </div>

                <template v-if="edit && edit.is_printable">
                  <p class="font-bold">
                    هزینه خدمات شهریه سرویس مدارس درسال تحصیلی 1403-1404
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <th>نوع خودرو</th>
                        <th>شهریه راننده</th>
                        <th>سهم شرکت حمل ونقل</th>
                        <th>مجموع پرداختی خانواده</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>انواع خودروهای سواری</th>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <th>انواع خودروهای جمعی</th>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    نرخ شهریه تا مجموع 5 کیلومتر 5/2 مسیررفت و5/2 برگشت ازمنزل
                    به مدرسه و بلعکس میباشد وبه ازای هریک کیلومترمازاد 5% به نرخ
                    مصوب اضافه میشود و همچنین مسیرهای ترافیکی مشمول 5% افزایش به
                    نرخ مصوب میباشد ؛ کمیسیون شرکتهای سرویس مدارس5% میباشد. جهت
                    رعایت حقوق شهروندان مبلغ دریافتی کمیسیون مازد بر ( 5 )
                    کیلومتر شرکتهای سرویس مدارس ازکیلومترمازاد 2% دریافت
                    مینمایند . مدت خدمات مقطع ابتدایی 8 ماه الزامی و ماه نهم
                    توافقی میباشد و مدت مقطع دوره اول و دوم متوسطه 9 ماه الزامی
                    میباشد مدت زمان مهد کودک ها بصورت توافقی ثبت میگردد. اولیاء
                    محترم چنانچه نرخ سوخت درطول زمان سرویس دهی افزایش یابد
                    درمبلغ شهریه بازنگری وطبق مصوبه نرخ توسط شرکت اطلاع رسانی
                    واولیاء مکلف به پرداخت نرخ اعلام شده میباشند . شرایط دریافت
                    شهریه سرویس مدارس : توضیحات و شرایط درخواست سرویس :
                  </p>
                  <p class="font-bold">
                    محاسبه مبلغ قرارداد سرویس مدارس 1403 - 1404
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <th>نرخ شهریه باتوجه به نوع خودرو</th>
                        <th>مبلغ کیلومتر مازاد ( )</th>
                        <th>مبلغ قرارداد ماهیانه</th>
                        <th>مدت قرارداد</th>
                        <th>مبلغ قرارداد سالانه</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="font-bold">
                    {{ "محاسبه حق الزحمه راننده شرکت سرویس مدارس " }}
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <th>حق الزحمه راننده بنسبت خودرو</th>
                        <th>مبلغ کیلومتر مازاد</th>
                        <th>مبلغ قرارداد ماهیانه راننده</th>
                        <th>مدت قرارداد</th>
                        <th>مبلغ قرارداد سالانه</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="font-bold">
                    محاسبه حق الزحمه شرکت سرویس مدارس
                    <span>{{ edit?.amount | price }}</span> اولیاءمحترم
                    می توانند کمیسیون شرکت را نقدأ و یا بطور اقساط طی یک فقره چک
                    صیادی تا 25 بهمن ماهیانه پرداخت نمایند .
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <th>کمیسیون 5%ماهیانه شرکت باتوجه به نوع خودرو</th>
                        <th>کمیسیون 2%ماهیانه شرکت کیلومترمازاد</th>
                        <th>مجموع کل مبلغ کمیسیون ماهیانه</th>
                        <th>مدت قرارداد</th>
                        <th>مبلغ کل کمیسیون سالیانه شرکت</th>
                        <th>مبلغ اقساط کمیسیون شرکت تا تسویه حساب</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </div>
            </div>
            <div class="border mt-8 p-4">
              <div>امضا ولی دانش آموز</div>
              <div>امضا راننده سرویس</div>
              <div>مهر و امضا مدیر شرکت</div>
            </div>
          </div>
        </div>
        <div
          class="col-12 d-flex align-items-center justify-content-center mt-4 dont-print"
        >
          <BButton @click.prevent="submitContract" variant="success"
            >ثبت تغییرات</BButton
          >
        </div>
      </div>
    </template>
    <template #modals>
      <BModal
        noCloseOnBackdrop
        @close="closeModal"
        id="contract_modal"
        title="ویرایش قرارداد"
        hideFooter
        size="md"
      >
        <BForm @submit.prevent="submitHandler">
          <FormSchema v-model="formData" :schema="schema" />
          <div class="col-12">
            <BButton variant="primary" type="submit" :disabled="disabled"
              >ثبت</BButton
            >
            <BButton
              @click="closeModal"
              variant="warning"
              type="button"
              class="mr-3"
              :disabled="disabled"
              >بستن</BButton
            >
          </div>
        </BForm>
      </BModal>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from "@@/core/components/layouts/MainLayout";
import crudMixin from "@@/core/mixins/crudMixin";
import FormSchema from "@@/core/components/shared/form/FormSchema";

import { computed } from "vue";
export default {
  mixins: [crudMixin],
  components: { MainLayout, FormSchema },
  data() {
    return {
      formData: {
        status: null,
        start_date: null,
        end_date: null,
        driver: null,
      },
      schema: [
        {
          tag: "date",
          type: "date",
          name: "start_date",
          class: "col-12 col-md-6",
          format: "YYYY/MM/DD",
          displayFormat: "jYYYY/jMM/jDD",
          readonly: false,
          required: true,
          label: "تاریخ شروع",
          placeholder: "تاریخ شروع",
        },
        {
          tag: "date",
          type: "date",
          name: "end_date",
          class: "col-12 col-md-6",
          format: "YYYY/MM/DD",
          displayFormat: "jYYYY/jMM/jDD",
          readonly: false,
          required: true,
          label: "تاریخ پایان",
          placeholder: "تاریخ پایان",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.create.drivers),
          name: "driver",
          title: "name",
          track_by: "id",
          class: "col-12 col-md-6",
          readonly: false,
          required: true,
          label: "راننده",
          placeholder: "راننده",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.create.statuses),
          name: "status",
          title: "label",
          track_by: "value",
          class: "col-12 col-md-6",
          readonly: false,
          required: true,
          label: "وضعیت",
          placeholder: "وضعیت",
        },
      ],
    };
  },
  created() {
    this.url = "/agency/contracts";
    this.edit_key = "contract";
    this.init_key = "contracts";
    this.modalId = "contract_modal";
    this.hasCreate = true;
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>
<style scoped>
table {
  width: 80%;
  margin: 16px auto;
  text-align: center;
  border: 1px solid #000 !important;
  border-collapse: collapse;
}

table thead tr,
table tbody tr {
  border: 1px solid #000;
  padding: 8px;
}

table thead th,
table tbody td,
table tbody th {
  border: 1px solid #000;
  padding: 8px;
}

@media print {
  .dont-print {
    display: none !important;
  }
}
</style>
