<template>
  <div class="app sidebar-mini" :class="sidebarStatus()">
    <!---Global-loader-->
    <!-- <div id="global-loader" >
        <img src="@@/assets/admin/images/svgs/loader.svg" alt="loader">
      </div>
     -->
    <div class="page">
      <div class="page-main">
        <!--aside open-->
        <Sidebar></Sidebar>
        <!--aside closed-->

        <div class="app-content main-content">
          <div class="side-app">
            <!--app header-->
            <Header></Header>
            <!--/app header-->
            <router-view :key="$route.path"></router-view>
          </div>
        </div>
        <!-- end app-content-->
      </div>

      <!--Footer-->
      <Footer></Footer>
      <!-- End Footer-->

      <!--Clock-IN Modal -->
      <div class="modal fade" id="clockinmodal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                <span class="feather feather-clock mr-1"></span>Clock In
              </h5>
              <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="countdowntimer">
                <span id="clocktimer" class="border-0"></span>
              </div>
              <div class="form-group">
                <label class="form-label">Note:</label>
                <textarea class="form-control" rows="3">
Some text here...</textarea
                >
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-outline-primary" data-dismiss="modal">
                Close
              </button>
              <button class="btn btn-primary">Clock In</button>
            </div>
          </div>
        </div>
      </div>
      <!-- End Clock-IN Modal  -->

      <!--Change password Modal -->
      <div class="modal fade" id="changepasswordnmodal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">تغییر رمز عبور</h5>
              <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label class="form-label">رمز عبور قبلی</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="پسورد قبلی"
                  :disabled="disabled"
                  v-model="oldPassword"
                />
              </div>
              <div class="form-group">
                <label class="form-label">رمز عبور جدید</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="پسورد جدید"
                  :disabled="disabled"
                  v-model="password"
                />
              </div>
              <div class="form-group">
                <label class="form-label">تکرار رمز عبور جدید</label>
                <input
                  type="password"
                  class="form-control"
                  placeholder="تکرار پسورد جدید"
                  :disabled="disabled"
                  v-model="confirmPassword"
                />
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="btn btn-outline-primary" data-dismiss="modal"
                >لغو</a
              >
              <a
                @click="changePassword"
                class="btn btn-primary"
                :disabled="disabled"
                >ثبت</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- End Change password Modal  -->
    </div>

    <!-- Back to top -->
    <a href="#top" id="back-to-top"
      ><span class="feather feather-chevrons-up"></span
    ></a>
  </div>
</template>

<script>
import Sidebar from "@@/core/components/superAdmin/shared/Sidebar/Sidebar";
import Footer from "@@/core/components/superAdmin/shared/Footer/Footer";
import Header from "@@/core/components/superAdmin/shared/Header/Header";
import mixins from "@@/core/mixins/mixins";
import adminAssets from "@@/core/mixins/adminAssets";

export default {
  name: "Index",
  components: {
    Sidebar,
    Footer,
    Header,
  },
  mixins: [mixins, adminAssets],
  data() {
    return {
      password: null,
      confirmPassword: null,
      oldPassword: null,
      images: [], // For gallery
    };
  },
  created() {

  },
  mounted() {
    this.$store.dispatch("admin/getSettingsFromserver");
  },
  methods: {
    async changePassword() {
      this.disabled = true;
      try {
        const response = await this.$axios.put("/admin/password", {
          old_password: this.oldPassword,
          password: this.password,
          password_confirmation: this.confirmPassword,
        });
        this.$root.notify(response.data.messsage, "success");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
    sidebarStatus() {
      // return this.$root.getCookie("sidebarStatus") == "close"
      //   ? "sidenav-toggled"
      //   : "";
    },
  },
};
</script>
<style lang="scss">
.sidebar-right {
  right: unset;
  left: 0;
}
.sidebar.sidebar-right {
  transform: translate(-100%, 0);
}
</style>
