<template>
  <MainLayout>
    <template #title>{{ edit?.title }}</template>
    <template #main-content>
      <BForm ref="form_ref" class="row" @submit.prevent="submitHandler">
        <FormSchema :schema="schema" v-model="formData" />
        <div class="col-12 mt-4">
          <BButton variant="primary" type="submit" :disabled="disabled"
            >ثبت</BButton
          >
        </div>
      </BForm>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from "@@/core/components/layouts/MainLayout";
import crudMixin from "@@/core/mixins/crudMixin";
import FormSchema from "@@/core/components/shared/form/FormSchema";
export default {
  components: { MainLayout, FormSchema },
  mixins: [crudMixin],
  data() {
    return {
      schema: [
        {
          tag: "input",
          type: "text",
          name: "title",
          class: "col-12 col-md-3",
          readonly: false,
          required: true,
          label: "نام شرکت",
          placeholder: "نام شرکت",
        },
        {
          tag: "input",
          type: "text",
          name: "manager_name",
          class: "col-12 col-md-3",
          readonly: false,
          required: true,
          label: "نام مدیر",
          placeholder: "نام مدیر",
        },
        {
          tag: "input",
          type: "tel",
          name: "mobile",
          class: "col-12 col-md-3",
          required: true,
          label: "شماره موبایل",
          placeholder: "شماره موبایل",
        },
        {
          tag: "input",
          type: "tel",
          name: "telephone",
          class: "col-12 col-md-3",
          required: false,
          label: "شماره تلفن",
          placeholder: "شماره تلفن",
        },
        {
          tag: "input",
          type: "text",
          name: "address",
          class: "col-12 col-md-3",
          required: false,
          label: "آدرس",
          placeholder: "آدرس",
        },
        {
          tag: "input",
          type: "password",
          name: "password",
          class: "col-12 col-md-3",
          required: true,
          label: "رمز عبور",
          placeholder: "رمز عبور",
        },
        {
          tag: "input",
          type: "password",
          name: "password_confirmation",
          class: "col-12 col-md-3",
          required: false,
          label: "تکرار رمز عبور",
          placeholder: "تکرار رمز عبور",
        },
        {
          tag: "input",
          type: "number",
          name: "account_number",
          class: "col-12 col-md-3",
          required: false,
          label: "شماره حساب",
          placeholder: "شماره حساب",
        },
        {
          tag: "input",
          type: "number",
          name: "card_number",
          class: "col-12 col-md-3",
          required: false,
          label: "شماره کارت",
          placeholder: "شماره کارت",
        },
        {
          tag: "input",
          type: "text",
          name: "sheba_number",
          class: "col-12 col-md-3",
          required: false,
          label: "شماره شبا",
          placeholder: "شماره شبا",
        },
        {
          tag: "input",
          type: "text",
          class: "col-12 col-md-3",
          readonly: true,
          required: false,
          label: "استان",
          placeholder: "گلستان",
        },
        {
          tag: "input",
          type: "text",
          class: "col-12 col-md-3",
          readonly: true,
          required: true,
          label: "شهر",
          placeholder: "گرگان",
        },
        {
          tag: "textarea",
          name: "resume",
          class: "col-12 col-md-4",
          required: false,
          label: "رزومه",
          placeholder: "رزومه",
        },
        {
          tag: "file",
          name: "logo",
          class: "col-12 col-md-3",
          required: false,
          label: "لوگو",
        },
        {
          tag: "checkbox",
          name: "status",
          class: "col-12 col-md-3",
          required: false,
          label: "وضعیت",
        },
      ],
      formData: {
        title: null,
        telephone: null,
        manager_mobile: null,
        manager_name: null,
        mobile: null,
        password: null,
        password_confirmation: null,
        city: null,
        address: null,
        account_number: null,
        card_number: null,
        sheba_number: null,
        resume: null,
        status: true,
        logo: null,
      },
    };
  },
  created() {
    this.url = "/admin/agencies";
    this.edit_key = "agency";
    this.hasCreate = true;
    this.backRoute = '/super-admin/companies'
  },
};
</script>
