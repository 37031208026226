<template>
  <MainLayout>
    <template #filter-fields>
      <BForm @submit.prevent="applyFilter">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <BButton class="ml-2" type="submit" variant="success">فیلتر</BButton>
          <BButton class="ml-2" v-b-toggle.search_filter variant="danger"
            >بستن</BButton
          >
          <BButton variant="warning">برداشتن فیلتر</BButton>
        </div>
      </BForm>
    </template>
    <template #main-content>
      <BTable
        v-if="initData && initData.data && initData.data.length"
        responsive
        hover
        bordered
        :items="initData.data"
        :fields="fields"
        ><template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(status)="data">
          <StatusBadge :status="data.item.status" />
        </template>
        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | dateTime }}
        </template>
        <template v-slot:cell(amount)="data">
          {{ data.item.amount | price }}
        </template>
        <template v-slot:cell(edit)="data">
          <router-link
            :to="`/companies-admin/contract/${data.item.id}`"
            class="btn btn-outline-primary"
          >
            <i class="fas fa-print"></i
          ></router-link>
        </template>
        <template v-slot:cell(payments)="data">
          <router-link
            :to="`/companies-admin/contract/payment/${data.item.id}`"
            class="btn btn-outline-info"
          >
            <i class="fas fa-money-bill-wave"></i
          ></router-link>
        </template>
      </BTable>
      <div v-else class="alert alert-danger">موردی یافت نشد!</div>
      <pagination
        v-if="initData && initData.data && initData.data.length"
        :disabled="disabled"
        :limit="2"
        :data="initData"
        @pagination-change-page="changePage"
      >
      </pagination>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from "@@/core/components/layouts/MainLayout";
import crudMixin from "@@/core/mixins/crudMixin";
import FormSchema from "@@/core/components/shared/form/FormSchema";

export default {
  mixins: [crudMixin],
  components: {
    MainLayout,
    FormSchema,
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "school.title",
          label: "مدرسه",
        },
        {
          key: "address",
          label: "آدرس",
        },
        {
          key: "family.name",
          label: "خانواده",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ",
        },
        {
          key: "edit",
          label: "مشاهده",
        },
        {
          key: "payments",
          label: "مالی",
        },
      ],
      filter: {
        family_id: null,
        school_type_id: null,
        grade_id: null,
        school_id: null,
        gender: null,
      },
    };
  },
  created() {
    this.url = "/agency/contracts";
    this.edit_key = "contract";
    this.init_key = "contracts";
    this.hasCreate = true;
  },
};
</script>
