<template>
  <MainLayout hasFilter hasModal :modalName="modalId">
    <template #filter-fields>
      <BForm @submit.prevent="applyFilter">
        <FormSchema v-model="filter" :schema="filterSchema" />
        <div class="w-100 d-flex align-items-center justify-content-center">
          <BButton class="ml-2" type="submit" variant="success">فیلتر</BButton>
          <BButton class="ml-2" v-b-toggle.search_filter variant="danger"
            >بستن</BButton
          >
        </div>
      </BForm>
    </template>
    <template #main-content>
      <BTable
        v-if="initData && initData.data && initData.data.length"
        responsive
        hover
        bordered
        :items="initData.data"
        :fields="fields"
        ><template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(car)="data">
          {{
            `${data.item.car_name} - ${data.item.car_color} - ${data.item.car_model}`
          }}
        </template>
        <template v-slot:cell(status)="data">
          <StatusBadge :status="data.item.status" />
        </template>
        <template v-slot:cell(edit)="data">
          <BButton @click="loadInitData(data.item)" variant="outline-primary">
            <i class="fas fa-edit"></i
          ></BButton>
        </template>
        <template v-slot:cell(delete)="data">
          <BButton @click="deleteItem(data.item.id)" variant="outline-danger">
            <i class="fas fa-trash-alt"></i>
          </BButton>
        </template>
      </BTable>
      <div v-else class="alert alert-danger">موردی یافت نشد!</div>
      <pagination
        v-if="initData && initData.data && initData.data.length"
        :disabled="disabled"
        :limit="2"
        :data="initData"
        @pagination-change-page="changePage"
      >
      </pagination>
    </template>
    <template #modals>
      <CreateEditModal
        :formDataProps="formData"
        :createProps="create"
        :disabledProps="disabled"
        @on-submit-handler="submitHandler"
        @on-close-modal="closeModal"
      />
    </template>
  </MainLayout>
</template>
<script>
import crudMixin from "@@/core/mixins/crudMixin";
import FormSchema from "@@/core/components/shared/form/FormSchema";
import MainLayout from "@@/core/components/layouts/MainLayout";
import CreateEditModal from "@@/core/pages/companies/drivers/CreateEdit.vue";
import { computed } from "vue";
export default {
  mixins: [crudMixin],
  components: {
    FormSchema,
    MainLayout,
    CreateEditModal,
  },
  data() {
    return {
      driver_national_code: null,
      driverInformation: {},
      formData: {
        car_type: null,
        gender: null,
        name: null,
        image: null,
        license: null,
        mobile: null,
        national_code: null,
        address: null,
        car_model: null,
        car_color: null,
        car_name: null,
        plaque_1: null,
        plaque_2: null,
        plaque_3: null,
        plaque_4: null,
        license_expiration_date: null,
        bank_name: null,
        account_number: null,
        sheba_number: null,
        card_number: null,
      },
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "name",
          label: "نام",
        },
        {
          key: "mobile",
          label: "موبایل",
        },
        {
          key: "car",
          label: "خودرو",
        },
        {
          key: "plaque",
          label: "پلاک",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      filter: {
        name: null,
        gender: null,
        mobile: null,
        status: null,
        national_code: null,
        agency_id: null,
        car_type: null,
      },
      filterSchema: [
        {
          tag: "input",
          type: "text",
          name: "name",
          class: "col-12 col-md-3",
          readonly: false,
          required: false,
          label: "نام",
          placeholder: "نام",
        },
        {
          tag: "input",
          type: "tel",
          name: "mobile",
          class: "col-12 col-md-3",
          required: false,
          label: "شماره موبایل",
          placeholder: "شماره موبایل",
        },
        {
          tag: "input",
          type: "number",
          name: "national_code",
          class: "col-12 col-md-3",
          required: false,
          label: "کدملی",
          placeholder: "کدملی",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.create.genders),
          name: "gender",
          title: "label",
          track_by: "value",
          class: "col-12 col-md-3",
          readonly: false,
          required: false,
          label: "جنسیت",
          placeholder: "جنسیت",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.create.carTypes),
          name: "car_type",
          title: "label",
          track_by: "value",
          class: "col-12 col-md-3",
          readonly: false,
          required: false,
          label: "نوع خودرو",
          placeholder: "نوع خودرو",
        },
      ],
    };
  },
  created() {
    this.url = "/agency/drivers";
    this.edit_key = "driver";
    this.init_key = "drivers";
    this.modalId = "driver_modal";
    this.hasCreate = true;
  },
  methods: {
    async checkDriverHandler() {
      this.disabled = true;
      const formData = new FormData();
      formData.append("national_code", this.driver_national_code);
      await this.$axios
        .post("/agency/drivers/inquiry", formData)
        .then((response) => {
          this.driverInformation = response.data.data.driver;
        })
        .catch((error) => {
          this.handleErrorNotification(error);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    async addDriverHandler() {
      this.disabled = true;
      const formData = new FormData();
      formData.append("driver_id", this.driverInformation?.id);
      await this.$axios
        .post("/agency/drivers/add", formData)
        .then((response) => {
          this.initData.unshift(response.data.data.driver);
        })
        .catch((error) => {
          this.handleErrorNotification(error);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
