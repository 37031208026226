<template>
  <fieldset>
    <legend class="d-block w-100 p-0 fs-14">
      {{ label }}
      <span v-if="required" class="text-danger">*</span>
    </legend>
    <slot></slot>
  </fieldset>
</template>
<script>
export default {
  name: "inputGroup",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
