export function errorNotif(error) {
  if (error.response && error.response.data && error.response.data.error) {
    let errors = error.response.data.error;
    if (Array.isArray(errors)) {
      this.$root.errorNotify(errors);
    } else {
      let errorsArray = errors.includes("|") ? errors.split("|") : [errors];
      errorsArray.forEach((error) => {
        this.$root.errorNotify(error);
      });
    }
  } else {
    this.$root.errorNotify("مشکلی رخ داده است.");
  }
}
export function successNotif(response) {
  if (response && response.data && response.data.message) {
    this.$root.notify(response.data.message, "success");
  }
}
export function changePage(page) {
  if (this.disabled) {
    return;
  }
  this.$router.replace({
    path: this.$route.path,
    query: { ...this.$route.query, page: page },
  });
}

export function refreshDataObject(items, response, key, id) {
  if (!id) {
    return;
  }
  let targetIndex = items.data?.findIndex((el) => id == el.id);
  let updatedItem = response.data.data[key];
  this.$set(this.initData.data, targetIndex, updatedItem);
  this.$forceUpdate();
}
