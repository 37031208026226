<template>
  <MainLayout>
    <template #title>
      <p class="font-bold invisible-print">{{ edit?.title }}</p>
      <span class="fs-14 invisible-print">{{ ` تعداد مدارس انتخاب شده : ${formData?.schools?.length}` }}</span>
    </template>
    <template #action-btns>
      <BButton
        class="invisible-print"
        :disabled="disabled"
        variant="primary"
        @click="print"
        ><i class="fas fa-print"></i
      ></BButton>
    </template>
    <template #main-content>
      <BForm class="row invisible-print" @submit.prevent="submitHandler">
        <template v-for="(school, index) in schoolsComputed">
          <InputGroup v-if="school" class="col-12 col-md-4 mb-4">
            <div class="d-flex align-items-center px-4">
              <input
                @change="check(school)"
                type="checkbox"
                :id="`school_${school.id}`"
              />
              <p class="text-dark m-0 mr-5 fs-12 w-s-nowrap">
                <span class="font-bold">{{ school.title }}</span> -
                {{ school.grade?.full_name }}
              </p>
            </div>
          </InputGroup>
        </template>
        <div class="w-100 d-flex justify-content-center mt-4">
          <BButton variant="primary" type="submit" :disabled="disabled"
            >ثبت</BButton
          >
        </div>
      </BForm>
      <div id="print_schools">
        <div class="visible-print">
          <h3>{{ edit?.title }}</h3>
          <template
            v-if="formData && formData.schools && formData.schools.length"
          >
            <div class="grid-layout">
              <p
                class="fs-18"
                v-for="(school, index) in formData.schools"
                :key="index"
              >
                {{ ` ${index + 1} - ${school.title}` }}
              </p>
            </div>
          </template>
        </div>
      </div>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from "@@/core/components/layouts/MainLayout";
import crudMixin from "@@/core/mixins/crudMixin";

export default {
  components: { MainLayout },
  mixins: [crudMixin],
  data() {
    return {
      formData: {
        schools: [],
      },
    };
  },
  created() {
    this.url = "/admin/agencies";
    this.reqUrl = `/admin/agencies/${this.$route.params.id}/schools`;
    this.edit_key = "agency";
    this.hasCreate = true;
    this.backRoute = "/super-admin/companies";
  },
  watch: {
    "formData.schools": function (newValue) {
      if (newValue && Array.isArray(newValue) && newValue.length) {
        this.checkExistSchools(newValue);
      }
    },
  },
  computed: {
    schoolsComputed() {
      if (this.create && this.create.schools && this.create.schools.length) {
        return this.create.schools;
      }
    },
  },
  methods: {
    check(school) {
      let index = this.formData.schools.findIndex((el) => el.id == school.id);
      if (index > -1) {
        this.formData.schools.splice(index, 1);
      } else {
        this.formData.schools.push(school);
      }
    },
    checkExistSchools(data) {
      data.forEach((school) => {
        if (document.getElementById(`school_${school.id}`)) {
          document
            .getElementById(`school_${school.id}`)
            .setAttribute("checked", true);
        }
      });
    },
    print() {
      window.print();
    },
  },
};
</script>
<style scoped>
.w-s-nowrap {
  white-space: nowrap;
}
@media screen {
  #print_schools .visible-print {
    display: none;
  }
}
@media print {
  .invisible-print {
    display: none;
    visibility: hidden;
  }
  #print_schools .visible-print {
    display: block;
  }
  #print_schools .grid-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-row-gap: 0.3rem;
    margin-top: 2rem;
  }
}
</style>
