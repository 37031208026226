<template>
  <MainLayout>
    <template #main-content>
      <BForm @submit.prevent="submitHandler">
        <FormSchema :schema="schema" v-model="formData">
          <template #extra-fields>
            <div class="w-100 px-2">
              <div class="d-flex align-items-center mb-3 mt-2">
                <p class="font-bold text-danger m-0 pr-2">توجه :</p>
                <p class="font-bold text-info m-0 pr-1">
                  برای انتخاب آدرس روی نقشه دوبار کلیک کنید.
                </p>
              </div>
              <LeafletMap v-model="location" :key="1" />
              <input
                v-if="location.position"
                v-model="location.position.lat"
                type="hidden"
                name="longitude"
              />
              <input
                v-if="location.position"
                v-model="location.position.lng"
                type="hidden"
                name="latitude"
              />
            </div>
          </template>
        </FormSchema>
        <div class="w-100 mt-5">
          <BButton type="submit" variant="primary">ثبت</BButton>
        </div>
      </BForm>
    </template>
  </MainLayout>
</template>
<script>
import LeafletMap from "@@/core/components/shared/leafletMap/LeafletMap.vue";
import crudMixin from "@@/core/mixins/crudMixin";
import FormSchema from "@@/core/components/shared/form/FormSchema";
import MainLayout from "@@/core/components/layouts/MainLayout";
import { computed } from "vue";
export default {
  components: {
    LeafletMap,
    FormSchema,
    MainLayout,
  },
  mixins: [crudMixin],
  data() {
    return {
      schema: [
        {
          tag: "input",
          type: "text",
          name: "title",
          class: "col-12 col-md-3",
          readonly: false,
          required: true,
          label: "نام مدرسه",
          placeholder: "نام مدرسه",
        },
        {
          tag: "input",
          type: "tel",
          name: "telephone",
          class: "col-12 col-md-3",
          readonly: false,
          required: false,
          label: "شماره تلفن",
          placeholder: "شماره تلفن",
        },
        {
          tag: "input",
          type: "text",
          name: "manager_name",
          class: "col-12 col-md-3",
          readonly: false,
          required: true,
          label: "نام مدیر",
          placeholder: "نام مدیر",
        },
        {
          tag: "input",
          type: "tel",
          name: "manager_mobile",
          class: "col-12 col-md-3",
          readonly: false,
          required: false,
          label: "شماره موبایل مدیر",
          placeholder: "شماره موبایل مدیر",
        },
        {
          tag: "input",
          type: "text",
          name: "address",
          class: "col-12 col-md-3",
          readonly: false,
          required: true,
          label: "آدرس",
          placeholder: "آدرس",
        },
        {
          tag: "input",
          type: "text",
          name: "",
          class: "col-12 col-md-3",
          readonly: true,
          required: true,
          label: "استان",
          placeholder: "گلستان",
        },
        {
          tag: "input",
          type: "text",
          name: "",
          class: "col-12 col-md-3",
          readonly: true,
          required: true,
          label: "شهر",
          placeholder: "گرگان",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.create.schoolTypes),
          name: "school_type",
          title: "title",
          track_by: "id",
          class: "col-12 col-md-3",
          readonly: false,
          required: true,
          label: "نوع مدرسه",
          placeholder: "نوع مدرسه",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.create.grades),
          name: "grade",
          title: "full_name",
          track_by: "id",
          class: "col-12 col-md-3",
          readonly: false,
          required: true,
          label: "مقطع تحصیلی",
          placeholder: "مقطع تحصیلی",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.create.shifts),
          name: "shift",
          title: "title",
          track_by: "id",
          class: "col-12 col-md-3",
          readonly: false,
          required: true,
          label: "شیفت مدرسه",
          placeholder: "شیفت مدرسه",
        },
        {
          tag: "checkbox",
          name: "is_traffic",
          class: "col-12 col-md-3",
          readonly: false,
          required: false,
          label: "ترافیک",
          placeholder: "ترافیک",
        },
        {
          tag: "checkbox",
          name: "status",
          class: "col-12 col-md-3",
          readonly: false,
          required: false,
          label: "وضعیت",
          placeholder: "وضعیت",
        },
      ],
      formData: {
        title: null,
        telephone: null,
        manager_mobile: null,
        manager_name: null,
        city: null,
        address: null,
        status: true,
        grade: null,
        shift: null,
        school_type: null,
        latitude: null,
        longitude: null,
        is_traffic: false,
      },
    };
  },
  created() {
    this.url = "/admin/schools";
    this.edit_key = "school";
    this.init_key = "schools";
    this.hasCreate = true;
    this.backRoute = "/super-admin/schools/school";
  },
  watch: {
    location: {
      handler(newValue) {
        if (newValue && !this.$root.isEmptyObject(newValue)) {
          this.location = newValue;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>
