<template>

    <l-map
      ref="map"
      :options="{ attributionControl: false }"
      @dblclick="onMapClick"
      style="height: 350px" 
      :zoom="zoom"
      :center="[
        position.lat || userLocation.lat || defaultLocation.lat,
        position.lng || userLocation.lng || defaultLocation.lng,
      ]"
    >
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker
        v-if="position && position.lat && position.lng"
        visible
        draggable
        :lat-lng.sync="position"
        :icon="icon"
        @dragstart="dragging = true"
        @dragend="dragging = false"
      >
        <l-tooltip
          :content="tooltipContent"
          :options="{ permanent: true, offset: tooltipOffset }"
        />
      </l-marker>
    </l-map>

</template>
<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import LocationMarker from "@@/core/assets/images/location-marker.png";
export default {
  components: { LMap, LTileLayer, LMarker, LTooltip },
  props: {
    value: {
      type: Object,
      required: true,
    },
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 36.8418,
        lng: 54.4328,
      }),
    },
  },
  data() {
    return {
      loading: false,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 18,
      icon: L.icon({
        iconUrl: LocationMarker,
        iconSize: [20, 28],
      }),
      tooltipOffset: L.point({ x: 300, y: 10 }),
      dragging: false,
      position: {},
      address: "",
      userLocation: {},
    };
  },
  mounted() {
    this.getUserPosition();
    this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
    this.$refs.map.mapObject.doubleClickZoom.disable();
  },
  watch: {
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getAddress();
        this.$emit("input", { position: value, address: this.address });
      },
    },
    value:{
      async handler(newValue) {
        if(newValue && !this.$root.isEmptyObject(newValue) && newValue.position){
          this.position = newValue.position;
        }
      },
      deep: true
    }
  },
  computed: {
    tooltipContent() {
      if (this.dragging) return "...";
      if (this.loading) return "Loading...";
      let location = this.address;
      if (location && location.address) {
        return `
        <strong>${
          location.address.road
            ? location.address.road
            : location.address.neighbourhood
        }</strong>
        <br/>
        <p>${location.address.country},${location.address.state},${
          location.address.city
        }</p>
        <hr/>
        <strong>lat:</strong> ${this.position.lat}
        <br/> 
        <strong>lng:</strong> ${this.position.lng}`;
      }
    },
  },
  methods: {
    async getAddress() {
      this.loading = true;
      let address = "Unresolved address";
      try {
        const { lat, lng } = this.position;
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        );
        if (result.status === 200) {
          const body = await result.json();
          address = body;
        }
      } catch (e) {
        console.error("Reverse Geocode Error->", e);
      }
      this.loading = false;
      return address;
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      this.position = value.latlng;
    },
    onSearch(value) {
      const loc = value.location;
      this.position = { lat: loc.y, lng: loc.x };
    },
    async getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition((pos) => {
          // set the user location
          this.userLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
        });
      }
    },
  },
};
</script>
<style scoped></style>
