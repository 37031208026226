import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=449bd257&scoped=true"
import script from "./Menu.vue?vue&type=script&lang=js"
export * from "./Menu.vue?vue&type=script&lang=js"
import style0 from "@@/core/components/superAdmin/sidebar/Menu/MenuStyle.css?vue&type=style&index=0&id=449bd257&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "449bd257",
  null
  
)

export default component.exports