<template>
  <MainLayout :isLoading="loading">
    <template #main-content>
      <div v-if="edit" class="row">
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">نام :</h6>
          <p class="m-0">{{ edit.name }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">موبایل :</h6>
          <p class="m-0">{{ edit.mobile }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">کد ملی :</h6>
          <p class="m-0">{{ edit.national_code }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">جنسیت :</h6>
          <p class="m-0">
            <template v-if="edit.gender == 'male'"> مرد </template>
            <template v-else-if="edit.gender == 'female'"> زن </template>
            <template v-else> _ </template>
          </p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">توضیحات :</h6>
          <p class="m-0">{{ edit.description }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">آدرس :</h6>
          <p class="m-0">{{ edit.address }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">خودرو :</h6>
          <p class="m-0">
            {{ `${edit.car_color} - ${edit.car_name}` }}
          </p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">مدل خودرو :</h6>
          <p class="m-0">{{ edit.car_model }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">تاریخ صدور مجوز :</h6>
          <p class="m-0">
            {{ edit.license_expiration_date | date }}
          </p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">نوع خودرو :</h6>
          <p class="m-0">
            <template v-if="edit.car_type == 'private'"> شخصی </template>
            <template v-else-if="edit.car_type == 'public'"> عمومی </template>
            <template v-else> _ </template>
          </p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">پلاک :</h6>
          <p class="m-0">{{ edit.plaque }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">بانک :</h6>
          <p class="m-0">{{ edit.bank_name }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">شماره کارت :</h6>
          <p class="m-0">{{ edit.card_number }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">شماره حساب :</h6>
          <p class="m-0">{{ edit.account_number }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">شماره شبا :</h6>
          <p class="m-0">{{ edit.sheba_number }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">وضعیت :</h6>
          <template v-if="edit.status == 'pending'">
            <BBadge variant="warning"> در حال پردازش</BBadge>
          </template>
          <template v-else-if="edit.status == 'confirmed'">
            <BBadge variant="success">تایید شده</BBadge>
          </template>
          <template v-else-if="edit.status == 'rejected'">
            <BBadge variant="danger">رد شده</BBadge>
          </template>
          <template v-else>
            <BBadge variant="secondary">نا مشخص</BBadge>
          </template>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">شرکت :</h6>
          <p class="m-0">{{ edit.agency?.title }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex align-items-center col-12 col-md-4 mb-5">
          <h6 class="m-0 ml-2 font-bold">تاریخ :</h6>
          <p class="m-0">{{ edit.created_at | dateTime }}</p>
        </div>
        <!-- ================= -->
        <div class="d-flex col-12 col-md-5 mb-5 align-items-start">
          <h6 class="m-0 ml-2 font-bold">مجوز :</h6>
          <img
            class="w-75"
            v-if="edit.license"
            :src="edit.license.url"
            alt=""
          />
        </div>
        <!-- ================= -->
        <div class="d-flex col-12 col-md-5 mb-5 align-items-start">
          <h6 class="m-0 ml-2 font-bold">تصویر راننده :</h6>
          <img
            v-if="edit.image"
            :src="edit.image.url"
            style="width: 35%;"
            alt=""
          />
        </div>
        <!-- ================= -->
      </div>
      <BCard class="border" header-tag="header">
        <template #header>
          <h4 class="mb-0 font-bold">ویرایش وضعیت</h4>
        </template>
        <BForm ref="form_ref" @submit.prevent="submitHandler">
          <FormSchema v-model="formData" :schema="schema" />
          <BButton type="submit" variant="primary">ثبت</BButton>
        </BForm>
      </BCard>
    </template>
  </MainLayout>
</template>
<script>
import crudMixin from "@@/core/mixins/crudMixin";
import FormSchema from "@@/core/components/shared/form/FormSchema";
import MainLayout from "@@/core/components/layouts/MainLayout";
import { computed } from "vue";

export default {
  mixins: [crudMixin],
  components: { FormSchema, MainLayout },
  data() {
    return {
      schema: [
        {
          tag: "textarea",
          name: "description",
          class: "col-12",
          readonly: false,
          required: false,
          label: "توضیحات",
          placeholder: "توضیحات",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.create.statuses),
          name: "status",
          title: "label",
          track_by: "value",
          class: "col-12",
          readonly: false,
          required: false,
          label: "وضعیت",
          placeholder: "وضعیت",
        },
      ],
      formData: {
        status: null,
        description: null,
      },
    };
  },
  created() {
    this.url = "/admin/drivers";
    this.edit_key = "driver";
    this.init_key = "drivers";
    this.hasCreate = true;
    this.hasTranslate = true;
    this.backRoute = "/super-admin/drivers/driver";
  },
};
</script>
