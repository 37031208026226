<template>
  <MainLayout hasModal :modalName="modalId">
    <template #main-content>
      <BTable
        v-if="initData && initData.data && initData.data.length"
        responsive
        hover
        bordered
        :items="initData.data"
        :fields="fields"
      >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(title)="data">
          {{ data.item.title }}
        </template>
        <template v-slot:cell(gender)="data">
          {{ data.item.gender == "girls" ? "دختر" : "پسر" }}
        </template>
        <template v-slot:cell(status)="data">
          <StatusBadge :status="data.item.status" />
        </template>
        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | dateTime }}
        </template>
        <template v-slot:cell(edit)="data">
          <BButton @click="loadInitData(data.item)" variant="outline-primary">
            <i class="fas fa-edit"></i
          ></BButton>
        </template>
        <template v-slot:cell(delete)="data">
          <BButton @click="deleteItem(data.item.id)" variant="outline-danger">
            <i class="fas fa-trash-alt"></i>
          </BButton>
        </template>
      </BTable>
      <div v-else class="alert alert-danger">موردی یافت نشد!</div>
      <pagination
        v-if="initData && initData.data && initData.data.length"
        :disabled="disabled"
        :limit="2"
        :data="initData"
        @pagination-change-page="changePage"
      >
      </pagination>
    </template>
    <template #modals>
      <BModal
        noCloseOnBackdrop
        @close="closeModal"
        id="grades_modal"
        title="افزودن مورد جدید"
        hideFooter
      >
        <BForm @submit.prevent="submitHandler">
          <FormSchema v-model="formData" :schema="schema"></FormSchema>
          <div class="col-12">
            <BButton variant="primary" type="submit" :disabled="disabled"
              >ثبت</BButton
            >
            <BButton
              @click="closeModal"
              variant="warning"
              type="button"
              class="mr-3"
              :disabled="disabled"
              >بستن</BButton
            >
          </div>
        </BForm>
      </BModal>
    </template>
  </MainLayout>
</template>
<script>
import crudMixin from "@@/core/mixins/crudMixin";
import MainLayout from "@@/core/components/layouts/MainLayout";
import FormSchema from "@@/core/components/shared/form/FormSchema";
import { computed } from "vue";
export default {
  mixins: [crudMixin],
  components: {
    MainLayout,
    FormSchema,
  },
  data() {
    return {
      genderOptions: [
        {
          value: "boys",
          text: "پسر",
        },
        {
          value: "girls",
          text: "دختر",
        },
      ],
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "full_name",
          label: "عنوان",
        },
        {
          key: "gender",
          label: "جنسیت",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      schema: [
        {
          tag: "input",
          type: "text",
          name: "title",
          class: "col-12",
          readonly: false,
          required: true,
          label: "عنوان",
          placeholder: "عنوان",
        },
        {
          tag: "textarea",
          name: "description",
          class: "col-12",
          readonly: false,
          required: false,
          label: "توضیحات",
          placeholder: "توضیحات",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.genderOptions),
          name: "gender",
          title: "text",
          track_by: "value",
          class: "col-12",
          readonly: false,
          required: true,
          label: "جنسیت",
          placeholder: "جنسیت",
        },
        {
          tag: "checkbox",
          name: "status",
          class: "col-12",
          readonly: false,
          required: false,
          label: "وضعیت",
          placeholder: "وضعیت",
        },
      ],
      formData: {
        title: null,
        gender: null,
        description: null,
        status: true,
      },
    };
  },
  created() {
    this.url = "/admin/grades";
    this.edit_key = "grade";
    this.init_key = "grades";
    this.modalId = "grades_modal";
    this.hasTranslate = true;
  },
};
</script>
<style scoped></style>
