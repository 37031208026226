import config from "@@/core/config";

export default class configProvider {
  static get(key, _default = null) {
    const fromCacheValue = this.getCache(key);
    if (fromCacheValue) {
      return fromCacheValue;
    }
    const keys = key.split(".");
    let value = config;
    let c = 0;
    do {
      let keyName = keys[c++];
      if (typeof value[keyName] === "undefined") {
        return _default;
      }
      value = value[keyName];
    } while (c < keys.length);

    return this.setCache(key, value);
  }

  static setCache(key, value) {
    if (!this.cache) {
      this.cache = {};
    }
    return (this.cache[key] = value);
  }

  static getCache(key) {
    if (!this.cache) {
      this.cache = {};
    }
    return this.cache[key];
  }
}
