<template>
  <div class="w-100">
    <Multiselect
      ref="multiselect"
      placeholder="انتخاب کنید"
      open-direction="bottom"
      v-model="realValue"
      :options="options"
      :name="name"
      :label="label"
      :track-by="trackby"
      :searchable="true"
      :close-on-select="true"
      :show-labels="false"
      :allow-empty="false"
      @select="selectedOption"
    >
      <template slot="noOptions"> موردی یافت نشد </template>
      <template slot="noResult"> موردی یافت نشد </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "multiselects",
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
    trackby: {
      type: String,
      default: "id",
    },
    label: {
      type: String,
      default: "title",
    },
    name: {
      type: String,
      default: "",
    },
    isArea: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      realValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      if (newValue) this.realValue = newValue;
    },
    realValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    selectedOption(item) {
      this.$emit("select-option", item);
    },
  },
};
</script>
