<template>
  <MainLayout>
    <template #main-content>
      <BTable
        v-if="initData && initData.data && initData.data.length"
        responsive
        hover
        bordered
        :items="initData.data"
        :fields="fields"
        ><template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(status)="data">
          <StatusBadge :status="data.item.status" />
        </template>
      </BTable>
      <div v-else class="alert alert-danger">موردی یافت نشد!</div>
      <pagination
        v-if="initData && initData.data && initData.data.length"
        :disabled="disabled"
        :limit="2"
        :data="initData"
        @pagination-change-page="changePage"
      >
      </pagination>
    </template>
  </MainLayout>
</template>
<script>
import crudMixin from "@@/core/mixins/crudMixin";
import MainLayout from "@@/core/components/layouts/MainLayout";
export default {
  mixins: [crudMixin],
  components: {
    MainLayout,
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "title",
          label: "مدرسه",
        },
        {
          key: "manager_name",
          label: "مدیر",
        },
        {
          key: "manager_mobile",
          label: "موبایل مدیر",
        },
        {
          key: "address",
          label: "آدرس",
        },
        {
          key: "telephone",
          label: "تلفن",
        },
        {
          key: "status",
          label: "وضعیت",
        },
      ],
    };
  },
  created() {
    this.url = "/agency/schools";
    this.edit_key = "school";
    this.init_key = "schools";
  },
};
</script>
