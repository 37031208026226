<template>
  <form autocomplete="off" @submit.prevent="submit">
    <div class="row">
      <b-form-group
        v-for="item in settings"
        :key="item.id"
        :class="item.type == 'text' ? 'col-md-6' : 'col-md-6'"
      >
        <label for=""> {{ item.label }} </label>
        <Ckeditor
          v-if="item.type === 'editor'"
          :id="`editor-${item.name}`"
          :name="item.name"
          :value="item.value"
          :disabled="disabled"
        />
        <b-form-input
          v-if="item.type == 'string' || item.type == 'number'"
          :name="item.name"
          :value="item.value"
          :disabled="disabled"
        >
        </b-form-input>
        <b-form-input
          v-if="item.type == 'integer'"
          type="number"
          :name="item.name"
          :value="item.value"
          :disabled="disabled"
        ></b-form-input>
        <template v-else-if="item.type == 'image'">
          <img src="" alt="" />
          <b-form-file
            placeholder="فایلی انتخاب نشده"
            :name="item.name"
            :disabled="disabled"
          ></b-form-file>
        </template>
        <template v-else-if="item.type == 'file'">
          <img src="" alt="" />
          <b-form-file
            placeholder="فایلی انتخاب نشده"
            :name="item.name"
            :disabled="disabled"
          ></b-form-file>
        </template>
        <b-form-textarea
          v-else-if="item.type == 'text' || item.type == 'textarea'"
          :name="item.name"
          :value="item.value"
          :disabled="disabled"
        ></b-form-textarea>
        <b-form-input
          v-else-if="item.type == 'price'"
          :value="item.value | priceinput"
          @input="
            (value) => {
              item.value = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          :name="item.name"
          :disabled="disabled"
        >
        </b-form-input>
        <multiselect
          v-else-if="item.type == 'multi_select'"
          v-model="item.value"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="products"
          label="label"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          @input="setVarieties"
        >
          <template slot="noOptions"> موردی یافت نشد </template>
          <template slot="noResult"> موردی یافت نشد </template>
        </multiselect>
      </b-form-group>
    </div>
    <div class="text-center">
      <button :disabled="disabled" type="submit" class="btn btn-success">
        اعمال تغییرات
      </button>
    </div>
  </form>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BTable,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";
import Ckeditor from "@@/core/components/shared/Ckeditor/Ckeditor.vue";

export default {
  name: "SettingForm",
  props: ["settings", "url"],
  components: {
    BFormGroup,
    BFormInput,
    BTable,
    BFormFile,
    BFormTextarea,
    Ckeditor,
  },
  data() {
    return {
      disabled: false,
      items: this.settings,
    };
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.disabled = true;
      try {
        let formData = new FormData(e.target);
        formData.append("_method", "PATCH");
        this.settings.forEach((item) => {
          if (item.type == "price") {
            formData.set(item.name, formData.get(item.name).replace(/\D/g, ""));
          }
        });
        const response = await this.$axios.post("admin/settings", formData);
        this.$root.notify(response, "success");
      } catch (error) {
        this.$root.notify(error);
      }
      this.disabled = false;
    },
  },
};
</script>
