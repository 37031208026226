<template>
  <div>
    <BBadge :variant="getStatusVariant(status)">{{
      label ? label : getStatusLabel(status)
    }}</BBadge>
  </div>
</template>
<script>
import { BBadge } from "bootstrap-vue";
export default {
  name: "status-badge",
  components: {
    BBadge,
  },
  props: {
    status: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: [String, Number],
      required: false,
    },
  },
  methods: {
    getStatusVariant(status) {
      switch (status) {
        case 1:
        case "1":
        case "confirmed":
        case "accepted_by_agency":
        case "accepted":
        case "registered":
        case "paid":
          return "success";
        case 0:
        case "0":
        case "rejected":
        case "canceled":
        case "canceled_by_family":
          return "danger";
        case "pending":
          return "warning";
        case "new":
          return "primary";
        default:
          return "secondary";
      }
    },
    getStatusLabel(status) {
      switch (status) {
        case 1:
        case "1":
          return "فعال";
        case 0:
        case "0":
          return "غیرفعال";
        case "confirmed":
          return "تایید شده";
        case "accepted":
          return "تایید شده";
        case "paid":
          return "پرداخت شده";
        case "accepted_by_agency":
          return "تایید توسط شرکت";
        case "rejected":
          return "رد شده";
        case "canceled":
          return "لغو شده";
        case "canceled_by_family":
          return "لغو توسط خانواده";
        case "pending":
          return "در حال بررسی";
        case "new":
          return "جدید";
        case "registered":
          return "ثبت شده";
        default:
          return "نامشخص";
      }
    },
  },
};
</script>
