export default class AssignDataToForm {
  constructor(model, $data) {
    this.keys = $data.keys;
    this.model = model;
    this.location = $data.location;
    this.keys = $data.keys;

    for (const key in $data.formData) {
      switch (key) {
        case "is_traffic":
        case "status":
          $data.formData[key] = this.statusModelResolver(model[key]);
          break;
        case "gender":
          $data.formData[key] = this.multiselectModelResolver(
            model[key],
            "gender"
          );
          break;
        case "schools":
          $data.formData[key] =
            model[key] && model[key].length ? model[key] : [];
          break;
        case "longitude":
        case "latitude":
          $data.formData[key] = model[key];
          $data.location = {
            position: this.locationResolver(model.latitude, model.longitude),
          };
          break;
        case "logo":
          $data.formData[key] = this.fileModelResolver(model[key]);
          break;
        default:
          $data.formData[key] = model[key];
          break;
      }
    }
  }
  statusModelResolver(status) {
    if (typeof status === "number") {
      if (status == 1) {
        return true;
      } else {
        return false;
      }
    } else if (typeof status === "object" || typeof status === "string") {
      return {
        label: this.keys[status],
        value: status,
      };
    } else {
      return null;
    }
  }
  locationResolver(latitude, longitude) {
    if (latitude && longitude) {
      let position = {
        lat: latitude,
        lng: longitude,
      };
      return position;
    } else {
      return {};
    }
  }
  fileModelResolver(file) {
    if (file && file.url) {
      return [];
    } else {
      return null;
    }
  }
  multiselectModelResolver(item, key) {
    if (item) {
      if (typeof item === "object" && !Array.isArray(item)) {
        return item;
      } else {
        return {
          label: this.keys[item[key]],
          value: item[key],
        };
      }
    } else {
      return null;
    }
  }
}
