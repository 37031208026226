<template>
  <MainLayout>
    <template #main-content>
      <div class="accordion mb-4 shadow-sm">
        <b-button
          class="accordion-button font-bold fs-16"
          block
          v-b-toggle.accordion_contract
          variant="light"
        >
          اطلاعات قرارداد
        </b-button>
        <b-collapse
          visible
          class="border custom-border-radius"
          id="accordion_contract"
        >
          <div class="p-4">
            <div class="row">
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">نام شرکت :</h6>
                <p class="m-0 pr-2">{{ edit?.agency?.title ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">شناسه :</h6>
                <div class="d-flex align-items-center">
                  <p class="m-0 pr-2">{{ edit?.id ?? "-" }}</p>
                  <router-link
                    class="btn btn-outline-info mr-2 px-2 py-0"
                    v-if="edit && edit.id"
                    :to="`/companies-admin/contract/${edit.id}`"
                  >
                    <i class="fas fa-eye fs-11"></i
                  ></router-link>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">وضعیت :</h6>
                <StatusBadge v-if="edit" class="pr-2" :status="edit.status" />
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">تعداد ماه های اقساط :</h6>
                <p class="m-0 pr-2">{{ edit?.months_count ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">تاریخ شروع :</h6>
                <p class="m-0 pr-2">
                  {{ dateFilter(edit?.start_date) ?? "-" }}
                </p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">تاریخ پایان :</h6>
                <p class="m-0 pr-2">{{ dateFilter(edit?.end_date) ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">مسافت :</h6>
                <p class="m-0 pr-2">{{ edit?.distance ?? "-" }} کیلومتر</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">مسافت اضافه :</h6>
                <p class="m-0 pr-2">
                  {{ edit?.extra_distance ?? "-" }} کیلومتر
                </p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">مبلغ پایه :</h6>
                <p class="m-0 pr-2">
                  {{ priceFilter(edit?.invoice?.base_amount) ?? "-" }} تومان
                </p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">مبلغ مسافت اضافی :</h6>
                <p class="m-0 pr-2">
                  {{ priceFilter(edit?.invoice?.extra_distance_amount) ?? "-" }}
                  تومان
                </p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">مبلغ ترافیک :</h6>
                <p class="m-0 pr-2">
                  {{ priceFilter(edit?.invoice?.traffic_amount) ?? "-" }} تومان
                </p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">مبلغ نهایی :</h6>
                <p class="m-0 pr-2">
                  {{ priceFilter(edit?.invoice?.amount) ?? "-" }} تومان
                </p>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="accordion mb-4 shadow-sm">
        <b-button
          class="accordion-button font-bold fs-16"
          block
          v-b-toggle.accordion_family
          variant="light"
        >
          اطلاعات خانواده
        </b-button>
        <b-collapse
          visible
          class="border custom-border-radius"
          id="accordion_family"
        >
          <div class="p-4">
            <div class="row">
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">نام و نام خانوادگی :</h6>
                <p class="m-0 pr-2">{{ edit?.family?.name ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">موبایل :</h6>
                <p class="m-0 pr-2">{{ edit?.family?.mobile ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">نام فرزند :</h6>
                <p class="m-0 pr-2">{{ edit?.child?.name ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">سن فرزند :</h6>
                <p class="m-0 pr-2">{{ edit?.child?.age ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">موبایل مادر :</h6>
                <p class="m-0 pr-2">{{ edit?.child?.mother_mobile ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">موبایل پدر :</h6>
                <p class="m-0 pr-2">{{ edit?.child?.father_mobile ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-4">
                <h6 class="font-bold m-0">تلفن ثابت :</h6>
                <p class="m-0 pr-2">{{ edit?.child?.telephone ?? "-" }}</p>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="accordion mb-4 shadow-sm">
        <b-button
          class="accordion-button font-bold fs-16"
          block
          v-b-toggle.accordion_driver
          variant="light"
        >
          اطلاعات راننده
        </b-button>
        <b-collapse
          visible
          class="border custom-border-radius"
          id="accordion_driver"
        >
          <div class="p-4">
            <div v-if="edit && edit.driver" class="row">
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">نام و نام خانوادگی :</h6>
                <p class="m-0 pr-2">{{ edit?.driver?.name ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">جنسیت :</h6>
                <p v-if="edit?.driver?.gender == 'male'" class="m-0 pr-2">
                  مرد
                </p>
                <p
                  v-else-if="edit?.driver?.gender == 'female'"
                  class="m-0 pr-2"
                >
                  زن
                </p>
                <p v-else class="m-0 pr-2">-</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">کد ملی :</h6>
                <p class="m-0 pr-2">{{ edit?.driver?.national_code ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">موبایل :</h6>
                <p class="m-0 pr-2">{{ edit?.driver?.mobile ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">پلاک :</h6>
                <p class="m-0 pr-2">{{ edit?.driver?.plaque ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">خودرو :</h6>
                <p v-if="edit && edit.driver" class="m-0 pr-2">
                  {{
                    `${edit.driver.car_name} - ${edit.driver.car_color} - ${edit.driver.car_model}`
                  }}
                </p>
                <p v-else class="m-0 pr-2">-</p>
              </div>
            </div>
            <div v-else class="alert alert-info">
              هنوز راننده ای ثبت نشده است .
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="accordion mb-4 shadow-sm">
        <b-button
          class="accordion-button font-bold fs-16"
          block
          v-b-toggle.accordion_school
          variant="light"
        >
          اطلاعات مدرسه
        </b-button>
        <b-collapse
          visible
          class="border custom-border-radius"
          id="accordion_school"
        >
          <div class="p-4">
            <div class="row">
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">نام :</h6>
                <p class="m-0 pr-2">{{ edit?.school?.title ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">مقطع :</h6>
                <p class="m-0 pr-2">{{ edit?.grade?.full_name ?? "-" }}</p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">نوع :</h6>
                <p class="m-0 pr-2">{{ edit?.schoolType?.title ?? "-" }}</p>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="accordion mb-4 shadow-sm">
        <b-button
          class="accordion-button font-bold fs-16"
          block
          v-b-toggle.accordion_installments
          variant="light"
        >
          اقساط
        </b-button>
        <b-collapse
          visible
          class="border custom-border-radius"
          id="accordion_installments"
        >
          <div class="p-4">
            <div v-if="invoice" class="row mb-4">
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">سهم راننده :</h6>
                <p class="m-0 pr-2">
                  {{ priceFilter(invoice?.driver_amount) ?? "-" }} تومان
                </p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">سهم شرکت :</h6>
                <p class="m-0 pr-2">
                  {{ priceFilter(invoice?.agency_amount) ?? "-" }} تومان
                </p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">سهم شتاب :</h6>
                <p class="m-0 pr-2">
                  {{ priceFilter(invoice?.shetab_amount) ?? "-" }} تومان
                </p>
              </div>
              <div class="d-flex align-items-center mb-3 col-12 col-md-3">
                <h6 class="font-bold m-0">سهم سازمان :</h6>
                <p class="m-0 pr-2">
                  {{ priceFilter(invoice?.organization_amount) ?? "-" }} تومان
                </p>
              </div>
            </div>
            <BTable
              v-if="
                invoice && invoice.installments && invoice.installments.length
              "
              responsive
              hover
              bordered
              :items="invoice.installments"
              :fields="fields"
              :tbody-tr-class="rowClass"
            >
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template v-slot:cell(status)="data">
                <StatusBadge
                  :label="data.item.status_label"
                  :status="data.item.status"
                />
              </template>
              <template v-slot:cell(due_date)="data">
                {{ data.item.due_date | date }}
              </template>
              <template v-slot:cell(amount)="data">
                {{ data.item.amount | price }} تومان
              </template>
              <template v-slot:cell(pay)="data">
                <b-button
                  :disabled="disabled"
                  @click="purchaseRequest(data.item.id)"
                  type="button"
                  class="fs-12"
                  :variant="
                    data.item.status == 'paid' ? 'success' : 'outline-primary'
                  "
                >
                  {{ data.item.status == "paid" ? "پرداخت شده" : "پرداخت" }}
                </b-button>
              </template>
            </BTable>
            <div v-else class="alert alert-danger">موردی یافت نشد!</div>
          </div>
        </b-collapse>
      </div>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from "@@/core/components/layouts/MainLayout";
import crudMixin from "@@/core/mixins/crudMixin";
export default {
  mixins: [crudMixin],
  components: { MainLayout },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "pay_by",
          label: "پرداخت توسط",
        },
        {
          key: "amount",
          label: "مبلغ",
        },
        {
          key: "payment.tracking_code",
          label: "شماره پیگیری",
        },
        {
          key: "description",
          label: "توضیح",
        },
        {
          key: "due_date",
          label: "تاریخ سررسید",
        },
        {
          key: "pay_date",
          label: "تاریخ پرداخت",
        },
        {
          key: "pay_by",
          label: "پرداخت توسط",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "pay",
          label: "پرداخت",
        },
      ],
      invoice: null,
    };
  },
  created() {
    this.url = "/agency/contracts";
    this.edit_key = "contract";
    this.init_key = "contracts";
  },
  watch: {
    edit: {
      handler(newValue) {
        if (newValue && newValue.invoice && newValue.invoice.id) {
          this.getInvoice(newValue.invoice.id);
        }
      },
      deep: true,
    },
  },
  methods: {
    dateFilter(date) {
      return this.$options.filters.date(date);
    },
    priceFilter(price) {
      return this.$options.filters.price(price);
    },
    async getInvoice(id) {
      try {
        this.disabled = true;
        const response = await this.$axios.get(`agency/invoices/${id}`);
        this.invoice = response.data.data.invoice;
      } catch (error) {
        this.errorNotif(error);
      } finally {
        this.disabled = false;
      }
    },
    async purchaseRequest(id) {
      try {
        this.disabled = true;
        let fd = new FormData();
        fd.append("installment_id", id);
        const response = await this.$axios.post("agency/payments/purchase", fd);
        let redirectUrl = response.data.data.url;
        window.location.href = redirectUrl;
        this.successNotif(response);
      } catch (error) {
        this.errorNotif(error);
      } finally {
        this.disabled = false;
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "paid") return "table-row-disabled";
    },
  },
};
</script>
<style scoped>
.accordion-button {
  border-radius: 5px 5px 0 0 !important;
  color: #000000 !important;
}
h6 {
  white-space: nowrap;
}
.custom-border-radius {
  border-radius: 0 0 5px 5px !important;
}
</style>
<style>
.table-row-disabled {
  opacity: 0.4 !important;
  pointer-events: none !important;
}
</style>
