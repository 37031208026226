<template>
  <MainLayout>
    <template #main-content>
      <template v-if="settings && settings.length">
        <SettingForm :settings="settings" :url="url" />
      </template>
      <div v-else class="alert alert-danger">موردی یافت نشد!</div>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from "@@/core/components/layouts/MainLayout";
import SettingForm from "@@/core/components/settings/SettingForm";
export default {
  components: { MainLayout, SettingForm },
  data() {
    return {
      settingKey: "social",
      settings: [],
      url: "/admin/settings",
    };
  },
  mounted() {
    this.loadSettingData();
  },
  methods: {
    async loadSettingData() {
      this.disabled = true;
      try {
        const responseSetting = await this.$axios.get(
          `${this.url}/${this.$route.params.group}`
        );
        this.settings = responseSetting.data.data.settings;
        console.log(responseSetting);
      } catch (error) {
        this.errorNotif(error);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
