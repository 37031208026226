<template>
  <div id="formSchema">
    <div class="row form-schema-container">
      <template v-for="$s in schema">
        <InputGroup
          class="input-group"
          :class="$s.class"
          :label="$s.label"
          :required="$s.required"
        >
          <!-- inputs -->
          <template v-if="$s.tag == 'input'">
            <BFormInput
              :readonly="$s.readonly"
              :type="$s.type"
              :name="$s.name"
              v-model="value[$s.name]"
              :placeholder="$s.placeholder"
              :value="$s.initial_value"
              autocomplete="off"
            />
          </template>
          <!-- checkbox -->
          <template v-else-if="$s.tag == 'checkbox'">
            <BFormCheckbox :name="$s.name" v-model="value[$s.name]" />
          </template>
          <!-- file input -->
          <template v-else-if="$s.tag == 'file'">
            <BFormFile :name="$s.name" v-model="value[$s.name]"
          /></template>
          <!-- select & options -->
          <template v-else-if="$s.tag == 'select'">
            <BFormSelect
              :options="$s.options"
              :name="$s.name"
              v-model="value[$s.name]"
          /></template>
          <!-- text-area -->
          <template v-else-if="$s.tag == 'textarea'">
            <BFormTextarea
              :name="$s.name"
              v-model="value[$s.name]"
              :placeholder="$s.placeholder"
              autocomplete="off"
          /></template>
          <!-- multiselect -->
          <template v-else-if="$s.tag == 'multiselect'">
            <Multiselects
              v-model="value[$s.name]"
              :options="$s.options"
              :trackby="$s.track_by"
              :label="$s.title"
              :name="$s.name"
            />
          </template>
          <!-- date-picker -->
          <template v-else-if="$s.tag == 'date'">
            <date-picker
              v-model="value[$s.name]"
              :type="$s.type"
              :format="$s.format"
              :display-format="$s.displayFormat"
              class="w-100"
            ></date-picker>
            <input :name="$s.name" type="hidden" v-model="value[$s.name]" />
          </template>
        </InputGroup>
      </template>
    </div>
    <div class="w-100"><slot name="extra-fields"></slot></div>
  </div>
</template>
<script>
import {
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import InputGroup from "@@/core/components/shared/inputGroup/InputGroup";
import Multiselects from "@@/core/components/shared/multiselect/Multiselects";
import debounce from "lodash.debounce";
export default {
  props: {
    schema: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    InputGroup,
    Multiselects,
    BForm,
    BFormCheckbox,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormInput,
  },
  watch: {
    value: {
      handler: debounce(function (newValue) {}, 800),
      deep: true,
    },
  },
};
</script>
<style scoped>
#formSchema .form-schema-container {
  position: relative;
  z-index: 1050;
}
#formSchema .form-schema-container .input-group {
  margin-bottom: 1rem;
}
</style>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.vpd-input-group .vpd-icon-btn {
  margin-bottom: 0;
}
</style>
