<template>
  <MainLayout>
    <template #main-content>
      <BTable
        v-if="initData && initData.data && initData.data.length"
        responsive
        hover
        bordered
        :items="initData.data"
        :fields="fields"
      >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(phone_number)="data">
          {{ data.item.phone_number ?? "-" }}
        </template>
        <template v-slot:cell(viewed_at)="data">
          <BBadge :variant="data.item.viewed_at ? 'success' : 'danger'">{{
            data.item.viewed_at ? "مشاهده شده" : "مشاهده نشده"
          }}</BBadge>
        </template>
        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | dateTime }}
        </template>
        <template v-slot:cell(detail)="data">
          <router-link :to="`/super-admin/contact/edit/${data.item.id}`" class="btn btn-outline-primary">
            <i class="fas fa-eye"></i>
          </router-link>
        </template>
      </BTable>
      <div v-else class="alert alert-danger">موردی یافت نشد!</div>
      <pagination
        v-if="initData && initData.data && initData.data.length"
        :disabled="disabled"
        :limit="2"
        :data="initData"
        @pagination-change-page="changePage"
      >
      </pagination>
    </template>
  </MainLayout>
</template>
<script>
import crudMixin from "@@/core/mixins/crudMixin";
import MainLayout from "@@/core/components/layouts/MainLayout";
export default {
  components: {
    crudMixin,
    MainLayout,
  },
  mixins: [crudMixin],
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "name",
          label: "نام",
        },
        {
          key: "phone_number",
          label: "موبایل",
        },
        {
          key: "viewed_at",
          label: "تاریخ مشاهده",
        },
        {
          key: "created_at",
          label: "تاریخ ایجاد",
        },
        {
          key: "detail",
          label: "جزئیات",
        },
      ],
      schema: [
        {
          tag: "textarea",
          name: "description",
          class: "col-12",
          required: true,
          label: "توضیحات ادمین",
          placeholder: "توضیحات ادمین",
        },
      ],
      formData:{
        description: null
      }
    };
  },
  created() {
    this.url = "/admin/contacts";
    this.edit_key = "contact";
    this.init_key = "contacts";
  },
};
</script>
