export default {
  name: "Header",
  data() {
    return {
      disabled: false,
    };
  },
  computed: {
    adminProfile() {
      return this.$store.state.admin.admin;
    },
    unreadNotifications() {
      return this.$store.state.admin.total_unread_notifications;
    },
    unreadContacts() {
      return this.$store.state.admin.total_unread_contacts;
    },
    contacts() {
      return this.$store.state.admin.contacts;
    },
  },
  beforeDestroy() {
    // sidebar.unbind("hidden.bs.sidebar", this.onSidebarHide);
  },
  mounted() {
    // setTimeout(() => {
    //   const sidebar = $("#sidebar");
    //   sidebar.unbind("hidden.bs.sidebar", this.onSidebarHide);
    //   sidebar.on("hidden.bs.sidebar", this.onSidebarHide);
    // }, 2000);
  },
  methods: {
    onSidebarHide() {
      this.readNotification();
    },
    toggleSidebar() {
      // let el = window.$(".app.sidebar-mini");
      // if (el.hasClass("sidenav-toggled")) {
      //   this.$root.setCookie("sidebarStatus", "open");
      // } else {
      //   this.$root.setCookie("sidebarStatus", "close");
      // }
    },
    async readNotification() {
      if (this.unreadNotifications != 0) {
        await this.$axios.post("admin/notifications/read");
        this.$store.commit("admin/setTotalUnreadNotifications", 0);
      }
    },
    logOut() {
      window
        .swal({
          title: "آیا میخواهید خارج شوید؟",

          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله خارج شو"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.disabled = true;
            this.$axios.post("/admin/logout").then((res) => {
              this.disabled = false;
              // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
              if (this.$route.fullPath.includes("super-admin")) {
                this.$root.deleteCookie("superAdminAuthorization");
                delete this.$axios.defaults.headers.common[
                  "superAdminAuthorization"
                ];
                this.$router.replace("/super-admin/login");
              }
            });
          }
        });
    },
  },
};
