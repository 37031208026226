<template>
  <MainLayout hasFilter>
    <template #filter-fields>
      <BForm @submit.prevent="applyFilter">
        <FormSchema v-model="filter" :schema="filterSchema" />
        <div class="w-100 d-flex align-items-center justify-content-center">
          <BButton class="ml-2" type="submit" variant="success">فیلتر</BButton>
          <BButton class="ml-2" v-b-toggle.search_filter variant="danger">بستن</BButton>
        </div>
      </BForm>
    </template>
    <template #main-content>
      <BTable
        v-if="initData && initData.data && initData.data.length"
        responsive
        hover
        bordered
        :items="initData.data"
        :fields="fields"
        ><template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(car)="data">
          {{
            `${data.item.car_name} - ${data.item.car_color} - ${data.item.car_model}`
          }}
        </template>
        <template v-slot:cell(status)="data">
          <StatusBadge :status="data.item.status" />
        </template>
        <template v-slot:cell(edit)="data">
          <router-link
            class="btn btn-outline-primary"
            :to="`/super-admin/drivers/driver/${data.item.id}`"
          >
            <i class="fas fa-eye"></i>
          </router-link>
        </template>
        <template v-slot:cell(delete)="data">
          <BButton @click="deleteItem(data.item.id)" variant="outline-danger">
            <i class="fas fa-trash-alt"></i>
          </BButton>
        </template>
      </BTable>
      <div v-else class="alert alert-danger">موردی یافت نشد!</div>
      <pagination
        v-if="initData && initData.data && initData.data.length"
        :disabled="disabled"
        :limit="2"
        :data="initData"
        @pagination-change-page="changePage"
      >
      </pagination>
    </template>
  </MainLayout>
</template>
<script>
import crudMixin from "@@/core/mixins/crudMixin";
import MainLayout from "@@/core/components/layouts/MainLayout";
import FormSchema from "@@/core/components/shared/form/FormSchema";
import { computed } from "vue";
export default {
  mixins: [crudMixin],
  components: {
    MainLayout,
    FormSchema,
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "name",
          label: "نام",
        },
        {
          key: "mobile",
          label: "موبایل",
        },
        {
          key: "car",
          label: "خودرو",
        },
        {
          key: "plaque",
          label: "پلاک",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "agency.title",
          label: "شرکت",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      filter: {
        name: null,
        gender: null,
        mobile: null,
        status: null,
        national_code: null,
        agency_id: null,
        car_type: null,
      },
      filterSchema: [
        {
          tag: "input",
          type: "text",
          name: "name",
          class: "col-12 col-md-3",
          readonly: false,
          required: false,
          label: "نام",
          placeholder: "نام",
        },
        {
          tag: "input",
          type: "tel",
          name: "mobile",
          class: "col-12 col-md-3",
          required: false,
          label: "شماره موبایل",
          placeholder: "شماره موبایل",
        },
        {
          tag: "input",
          type: "number",
          name: "national_code",
          class: "col-12 col-md-3",
          required: false,
          label: "کدملی",
          placeholder: "کدملی",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.create.statuses),
          name: "status",
          title: "label",
          track_by: "value",
          class: "col-12 col-md-3",
          readonly: false,
          required: false,
          label: "وضعیت",
          placeholder: "وضعیت",
        },
      ],
    };
  },
  created() {
    this.url = "/admin/drivers";
    this.edit_key = "driver";
    this.init_key = "drivers";
    this.hasCreate=true;
  },
};
</script>
<style scoped>
h6 {
  white-space: nowrap;
}
</style>
