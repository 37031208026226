<template>
  <div>
    <!--Page header-->
    <div class="page-header d-xl-flex d-block mt-0 mb-3">
      <div class="page-leftheader">
        <h4 class="page-title">
          <span class="font-weight-normal text-muted ml-2">داشبورد</span>
        </h4>
      </div>
    </div>
    <!--End Page header-->
    <BRow>
      <BCol md="3">
        <BCard no-body class="w-100 overflow-hidden">
          <BRow class="w-100 align-items-center justify-content-between">
            <BCol md="8">
              <BCardBody>
                <BCardText>
                  <p class="m-0 fs-18">تعداد قرارداد ها</p>
                  <h3 class="m-0 font-bold mt-3 mr-2">
                    {{ items.contractsCount }}
                  </h3>
                </BCardText>
              </BCardBody>
            </BCol>
            <BCol md="4">
              <BButton class="rounded-circle px-5 py-5" variant="primary">
                <i class="fas fa-file-contract fs-20"></i>
              </BButton>
            </BCol>
          </BRow>
        </BCard>
      </BCol>
      <BCol md="3">
        <BCard no-body class="w-100 overflow-hidden">
          <BRow class="w-100 align-items-center justify-content-between">
            <BCol md="8">
              <BCardBody>
                <BCardText>
                  <p class="m-0 fs-18">تعداد مدارس</p>
                  <h3 class="m-0 font-bold mt-3 mr-2">
                    {{ items.schoolsCount }}
                  </h3>
                </BCardText>
              </BCardBody>
            </BCol>
            <BCol md="4">
              <BButton class="rounded-circle px-5 py-5" variant="warning">
                <i class="fas fa-school fs-20"></i>
              </BButton>
            </BCol>
          </BRow>
        </BCard>
      </BCol>
      <BCol md="3">
        <BCard no-body class="w-100 overflow-hidden">
          <BRow class="w-100 align-items-center justify-content-between">
            <BCol md="8">
              <BCardBody>
                <BCardText>
                  <p class="m-0 fs-18">تعداد رانندگان</p>
                  <h3 class="m-0 font-bold mt-3 mr-2">
                    {{ items.driversCount }}
                  </h3>
                </BCardText>
              </BCardBody>
            </BCol>
            <BCol md="4">
              <BButton class="rounded-circle px-5 py-5" variant="success">
                <i class="fas fa-car fs-20"></i>
              </BButton>
            </BCol>
          </BRow>
        </BCard>
      </BCol>
    </BRow>
    <BRow>
      <BCol class="card" md="8">
        <p class="m-0 pr-2 text-dark fs-18 pt-3">قرارداد های جدید</p>
        <BTable
          v-if="items && items.newContracts && items.newContracts.length"
          responsive
          hover
          bordered
          :items="items.newContracts"
          :fields="fields"
          class="pt-3"
          ><template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(status)="data">
            <StatusBadge :status="data.item.status" />
          </template>
          <template v-slot:cell(detail)="data">
            <router-link
              class="btn btn-outline-warning"
              :to="`companies-admin/contract/payment/${data.item.id}`"
              >جزئیات</router-link
            >
          </template>
        </BTable>
        <div v-else class="alert alert-danger mb-3 mt-3">موردی یافت نشد!</div>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BCardText,
  BButton,
  BTable,
} from "bootstrap-vue";
import StatusBadge from "@@/core/components/shared/status/StatusBadge";
export default {
  name: "Dashboard",
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BCardText,
    BButton,
    StatusBadge,
    BTable,
  },
  data() {
    return {
      items: {},
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "family.name",
          label: "خانواده",
        },
        {
          key: "school.title",
          label: "مدرسه",
        },
        {
          key: "child.name",
          label: "فرزند",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "detail",
          label: "جزئیات",
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await this.$axios
        .get("/agency")
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {
          this.$root.notify(error);
        });
    },
  },
};
</script>

<style scoped></style>
