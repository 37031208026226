<template>
  <MainLayout>
    <template #main-content>
      <BTable
        v-if="initData && initData.data && initData.data.length"
        responsive
        hover
        bordered
        :items="initData.data"
        :fields="fields"
        ><template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(status)="data">
          <StatusBadge :status="data.item.status" />
        </template>
        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | dateTime }}
        </template>
        <template v-slot:cell(detail)="data">
          <BButton @click="loadInitData(data.item)" variant="outline-primary">
            <i class="fas fa-eye"></i
          ></BButton>
        </template>
      </BTable>
      <div v-else class="alert alert-danger">موردی یافت نشد!</div>
      <pagination
        v-if="initData && initData.data && initData.data.length"
        :disabled="disabled"
        :limit="2"
        :data="initData"
        @pagination-change-page="changePage"
      >
      </pagination>
    </template>
    <template #modals>
      <BModal
        noCloseOnBackdrop
        @close="closeModal"
        :id="modalId"
        title="ویرایش"
        hideFooter
      >
        <BForm @submit.prevent="submitHandler">
          <div class="d-flex align-items-center mb-5">
            <h5 class="mb-0 font-bold">نام و نام خانوادگی :</h5>
            <span class="mr-3">{{ edit?.name }}</span>
          </div>
          <div class="d-flex align-items-center mb-5">
            <h5 class="mb-0 font-bold">موبایل :</h5>
            <span class="mr-3">{{ edit?.mobile }}</span>
          </div>
          <div class="d-flex align-items-center mb-5">
            <h5 class="mb-0 font-bold">وضعیت :</h5>
            <span class="mr-3"><StatusBadge :status="edit?.status" /></span>
          </div>
          <FormSchema v-model="formData" :schema="schema"></FormSchema>
          <div class="col-12">
            <BButton variant="primary" type="submit" :disabled="disabled"
              >ثبت</BButton
            >
            <BButton
              @click="closeModal"
              variant="warning"
              type="button"
              class="mr-3"
              :disabled="disabled"
              >بستن</BButton
            >
          </div>
        </BForm>
      </BModal>
    </template>
  </MainLayout>
</template>
<script>
import crudMixin from "@@/core/mixins/crudMixin";
import MainLayout from "@@/core/components/layouts/MainLayout";
import FormSchema from "@@/core/components/shared/form/FormSchema";
import { computed } from "vue";
export default {
  mixins: [crudMixin],
  components: {
    MainLayout,
    FormSchema,
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "ردیف",
        },
        {
          key: "name",
          label: "نام",
        },
        {
          key: "mobile",
          label: "موبایل",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ",
        },
        {
          key: "detail",
          label: "مشاهده",
        },
      ],
      statuses: [
        {
          label: "در حال بررسی",
          value: "pending",
        },
        {
          label: "ثبت شده",
          value: "registered",
        },
        {
          label: "رد شده",
          value: "canceled",
        },
      ],
      formData: {
        status: null,
        description: null,
      },
      schema: [
        {
          tag: "textarea",
          name: "description",
          class: "col-12",
          required: true,
          label: "توضیحات ادمین",
          placeholder: "توضیحات",
        },
        {
          tag: "multiselect",
          conditions: "$s.options && $s.options.length",
          options: computed(() => this.statuses),
          name: "status",
          title: "label",
          track_by: "value",
          class: "col-12",
          readonly: false,
          required: true,
          label: "وضعیت",
          placeholder: "وضعیت",
        },
      ],
    };
  },
  created() {
    this.url = "/agency/driver-registrations";
    this.edit_key = "driverRegistration";
    this.init_key = "driverRegistrations";
    this.modalId = "registration_modal";
  },
};
</script>
