<template>
  <div>
    <!-- <form>
      <div>
        <b-form-input @input="menuSearch" type="search" class="form-control search-input" placeholder="جستجو" />
      </div>
    </form> -->
    <ul v-if="!search" class="side-menu">
      <menu-item v-for="(menu, index) in menus" :key="index" :menu="menu" />
    </ul>
    <ul v-else-if="search" class="side-menu">
      <menu-item
        v-for="(menu, index) in searchMenu"
        :key="index"
        :menu="menu"
      />
    </ul>
  </div>
</template>
<script>
import MenuImpl from "@@/core/components/companies/sidebar/Menu/MenuImpl";
export default {
  extends: MenuImpl,
};
</script>
<style
  scoped
  src="@@/core/components/companies/sidebar/Menu/MenuStyle.css"
></style>
