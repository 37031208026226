<template>
  <MainLayout>
    <template #main-content>
      <BForm @submit.prevent="submitForm" class="row align-items-baseline">
        <BFormGroup class="col-12" label="group">
          <BFormSelect :options="options" v-model="selected_group" />
        </BFormGroup>
        <BFormGroup class="col-12" label="name"
          ><BFormInput type="text" name="name"></BFormInput
        ></BFormGroup>
        <BFormGroup class="col-12" label="label"
          ><BFormInput type="text" name="label"></BFormInput
        ></BFormGroup>
        <BFormGroup class="col-12" label="type">
          <p class="text-info">
            ['text', 'textarea', 'image' , 'video', 'number']
          </p>
          <BFormInput type="text" name="type"></BFormInput>
        </BFormGroup>
        <BButton variant="primary" :disabled="disabled" type="submit"
          >ثبت</BButton
        >
      </BForm>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from "@@/core/components/layouts/MainLayout";
import {
  BForm,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    MainLayout,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
  },
  data() {
    return {
      options: ["general", "social", "app"],
      selected_group: "general",
      disabled: false,
    };
  },
  methods: {
    async submitForm(e) {
      this.disabled = true;
      try {
        const fd = new FormData(e.target);
        const response = await this.$axios.post(
          `/admin/settings/${this.selected_group}`,
          fd
        );
        this.$root.notify(response.data.message, "success");
      } catch (error) {
        console.log(error.response.data);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
