export default class FormRequest {
  constructor($data, form) {
    this.form = form;
    this.formData = $data.formData;
    this.edit = $data.edit;
    this.mode = $data.mode;
    this.location = $data.location;
  }

  async generateRequestData() {
    let data = {
      _method: this.mode == "edit" ? "PATCH" : null,
      title: this.formData.title ? this.formData.title : null,
      telephone: this.formData.telephone ? this.formData.telephone : null,
      manager_mobile: this.formData.manager_mobile ? this.formData.manager_mobile : null,
      manager_name: this.formData.manager_name ? this.formData.manager_name : null,
      mobile: this.formData.mobile ? this.formData.mobile : null,
      password: this.formData.password ? this.formData.password : null,
      password_confirmation: this.formData.password_confirmation ? this.formData.password_confirmation : null,
      // city_id: this.formData.city ? this.formData.city.id : null,
      city_id: 862, //Static for now
      address: this.formData.address ? this.formData.address : null,
      account_number: this.formData.account_number ? this.formData.account_number : null,
      card_number: this.formData.card_number ? this.formData.card_number : null,
      sheba_number: this.formData.sheba_number ? this.formData.sheba_number : null,
      resume: this.formData.resume ? this.formData.resume : null,
      logo: this.formData.logo ? this.fileResolver(this.formData.logo) : null,
      grade_id: this.formData.grade ? this.formData.grade.id : null,
      shift_id: this.formData.shift ? this.formData.shift.id : null,
      school_type_id: this.formData.school_type ? this.formData.school_type.id : null,
      driver_id: this.formData.driver ? this.formData.driver.id : null,
      latitude: this.location?.position?.lat ? this.location.position.lat : null,
      longitude: this.location?.position?.lng ? this.location.position.lng : null,
      is_traffic: this.statusResolver(this.formData.is_traffic),
      description: this.formData.description ? this.formData.description : null,
      gender: this.formData.gender?.value ? this.formData.gender?.value : null,
      status: this.statusResolver(this.formData.status),
      car_type: this.formData.car_type ? this.formData.car_type.value : null,
      name: this.formData.name ? this.formData.name : null,
      image: this.formData.image ? this.fileResolver(this.formData.image) : null,
      license: this.formData.license ? this.fileResolver(this.formData.license) : null,
      national_code: this.formData.national_code ? this.formData.national_code : null,
      car_model: this.formData.car_model ? this.formData.car_model : null,
      car_color: this.formData.car_color ? this.formData.car_color : null,
      car_name: this.formData.car_name ? this.formData.car_name : null,
      plaque_1: this.formData.plaque_1 ? this.formData.plaque_1 : null,
      plaque_2: this.formData.plaque_2 ? this.formData.plaque_2 : null,
      plaque_3: this.formData.plaque_3 ? this.formData.plaque_3 : null,
      plaque_4: this.formData.plaque_4 ? this.formData.plaque_4 : null,
      license_expiration_date: this.formData.license_expiration_date ? this.formData.license_expiration_date : null,
      bank_name: this.formData.bank_name ? this.formData.bank_name : null,
      start_date : this.formData.start_date ? this.formData.start_date : null, 
      end_date : this.formData.end_date ? this.formData.end_date : null
    };
    await this.schoolsResolver(this.formData.schools, data);
    let _formData = this.generateFormData(data);
    return _formData;
  }
  generateFormData(data) {
    const formDataInstance = new FormData();
    for (const key in data) {
      if (data[key] || data[key] == 0) {
        formDataInstance.append(key, data[key]);
      }
    }
    return formDataInstance;
  }
  statusResolver(status) {
    if (typeof status === "boolean") {
      if (status) {
        return 1;
      } else {
        return 0;
      }
    } else if (typeof status === "object") {
      return status?.value;
    } else {
      return null;
    }
  }
  fileResolver(file) {
    if (file) {
      if (Array.isArray(file) && file.length) {
        return file[0];
      } else {
        return file;
      }
    } else {
      return null;
    }
  }
  schoolsResolver(schools, data) {
    if (schools && schools.length) {
      schools.forEach((school, index) => {
        if (isNaN(school)) {
          data[`schools[${index}]`] = school.id;
        } else {
          data[`schools[${index}]`] = school;
        }
      });
    }
  }
}
