<template>
  <div class="page login-bg">
    <div class="page-single">
      <div class="container">
        <div class="row">
          <div class="col mx-auto">
            <div class="row justify-content-center">
              <div class="col-md-7 col-lg-5">
                <div class="card">
                  <div class="p-4 pt-6 text-center">
                    <h1 class="mb-2 font-bold">ورود</h1>
                    <p class="text-muted">ورود به پنل مدیر ارشد</p>
                  </div>
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form
                      @submit.prevent="
                        (e) => handleSubmit(() => submitLogin(e))
                      "
                      class="card-body pt-3"
                      id="login"
                    >
                      <ValidationProvider
                        name="mobile"
                        class="mb-3 text-right"
                        v-slot="v"
                      >
                        <div class="form-group">
                          <label class="form-label">شماره موبایل</label>
                          <input
                            :disabled="sendingRequest"
                            class="form-control"
                            type="text"
                            @change="v.validate"
                            name="mobile"
                            placeholder="نام شماره موبایل"
                          />
                          <span
                            class="text-right text-error text-danger mt-2"
                            >{{ v.errors[0] }}</span
                          >
                        </div>
                      </ValidationProvider>
                      <ValidationProvider
                        name="password"
                        class="mb-3 text-right"
                        v-slot="v"
                      >
                        <div class="form-group">
                          <label class="form-label">رمز عبور</label>
                          <input
                            :disabled="sendingRequest"
                            class="form-control"
                            name="password"
                            @change="v.validate"
                            placeholder="رمزعبور"
                            type="password"
                          />
                          <span
                            class="text-right text-error text-danger mt-2"
                            >{{ v.errors[0] }}</span
                          >
                        </div>
                      </ValidationProvider>
                      <div class="submit">
                        <button
                          :disabled="sendingRequest"
                          type="submit"
                          class="btn btn-primary btn-block"
                        >
                          ورود
                        </button>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "Login",
  mixins: [],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      sendingRequest: false,
    };
  },
  methods: {
    async submitLogin(e) {
      try {
        this.sendingRequest = true;
        let formData = new FormData(e.target);
        const response = await this.$axios.post("/admin/login", formData);
        this.$root.setCookie(
          "superAdminAuthorization",
          `Bearer ${response.data.data.data.access_token}`
        );
        this.$axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.data.data.access_token}`;
        this.$root.notify(response.data.message, "success");
        this.$router.push("/super-admin");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.sendingRequest = false;
      }
    },
  },
  mounted() {
    console.log(this.$route);
  },
};
</script>

<style scoped>
@font-face {
  font-family: "iranSans";
  font-weight: normal;
  src: url("../../../../assets/fonts/fonts_sans/woff2/IRANSansWeb\(FaNum\).woff2")
      format("woff2"),
    url("../../../../assets/fonts/fonts_sans/woff/IRANSansWeb\(FaNum\).woff")
      format("woff");
}

.login-bg * {
  font-family: iranSans;
  font-weight: 400;
}
.page,
.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}
.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  border: 0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(196, 205, 224, 0.2);
  border-radius: 13px;
}
.login-bg,
.message-bg {
  background: url("../../../../../assets/admin/images/photos/login1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.page-single,
.page-single-content {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
